export type InvoiceGenerationOptionEnumType = { label: string, value: InvoiceGenerationOptionEnum};

export enum InvoiceGenerationOptionEnum {
    ONE_LINE_PER_PRODUCT_ACCOUNT = 'ONE_LINE_PER_PRODUCT_ACCOUNT',
    ONE_LINE_PER_INVOICE_LINE = 'ONE_LINE_PER_INVOICE_LINE',
    ONE_LINE_PER_VAT_RATE = 'ONE_LINE_PER_VAT_RATE',
    ONE_GLOBAL_LINE = 'ONE_GLOBAL_LINE'
}

export const sellInvoiceGenerationOptions: InvoiceGenerationOptionEnumType[] = [
    {
        label: 'Détaillé : 1 ligne de facture = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
    },
    {
        label: 'Regroupé : 1 compte de produit = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
    },
    {
        label: 'Regroupé : 1 taux de tva = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE
    },
    {
        label: 'Unique : 1 vente = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE
    }
]

export const buyInvoiceGenerationOptions: InvoiceGenerationOptionEnumType[] = [
    {
        label: 'Détaillé : 1 ligne de facture = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
    },
    {
        label: 'Regroupé : 1 compte de charge = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
    },
    {
        label: 'Regroupé : 1 taux de tva = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE
    },
    {
        label: 'Unique : 1 achat = 1 ligne d\'écriture',
        value: InvoiceGenerationOptionEnum.ONE_GLOBAL_LINE
    }
]