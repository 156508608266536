export type JournalOptionType = { label: string, value: JournalOptionEnum};

export enum JournalOptionEnum {
    UNIQUE = 'UNIQUE',
    BY_PAYMENT_METHOD = 'BY_PAYMENT_METHOD'
}

export const paymentJournalOptions: JournalOptionType[] = [
    {
        label: 'Journal unique',
        value: JournalOptionEnum.UNIQUE
    },
    {
        label: 'Journal par méthode d\'encaissement',
        value: JournalOptionEnum.BY_PAYMENT_METHOD
    },
]

export const bankJournalOptions: JournalOptionType[] = [
    {
        label: 'Journal unique',
        value: JournalOptionEnum.UNIQUE
    },
    {
        label: 'Journal par banque',
        value: JournalOptionEnum.BY_PAYMENT_METHOD
    },
]