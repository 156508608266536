import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getBuyInvoiceEntryLineDescriptionFieldOptions, getSellInvoiceEntryLineDescriptionFieldOptions, clientThirdPartTemplatingNameFieldOption, clientThirdPartyTemplatingOptions, supplierThirdPartyTemplatingOptions, supplierThirdPartTemplatingNameFieldOption, sellInvoiceEntryLineDescriptionThirdPartFieldOption, buyInvoiceEntryLineDescriptionThirdPartFieldOption, getSellInvoiceEntryDescriptionFieldOptions, getBuyInvoiceEntryDescriptionFieldOptions, defaultEntryDescriptionEmptyFieldOption, getBankEntryDescriptionFieldOptions, getBankEntryLineDescriptionFieldOptions, bankEntryLineDescriptionDescriptionFieldOption } from './default-transformation-fields';
import { DocumentRefOptionEnum, getDocumentRefOptions } from '~modules/connectors/constants/document-ref.option';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';

const axonautBankEntryDescriptionOptions: DescriptionOption[] = getBankEntryDescriptionFieldOptions();

const axonautBankEntryLineDescriptionOptions: DescriptionOption[] = getBankEntryLineDescriptionFieldOptions();

const axonautSellInvoiceEntryDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryDescriptionFieldOptions({
    withThirdPart: true
});

const axonautSellInvoiceEntryLineDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

const axonautBuyInvoiceEntryDescriptionOptions: DescriptionOption[] = getBuyInvoiceEntryDescriptionFieldOptions({
    withThirdPart: true
});

const axonautBuyInvoiceEntryLineDescriptionOptions: DescriptionOption[] = getBuyInvoiceEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

export const axonautTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Axonaut',

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VT'
            },
            label: {
                defaultValue: 'Ventes'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: ''
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Clients'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CLIENTS'
            },
            auxiliaryDynamic: {
                options: clientThirdPartyTemplatingOptions,
                defaultOption: clientThirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
            },
            oneLinePerVatRate: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: null,
                            rate: 0,
                            account: '701000',
                            accountLabel: 'Ventes TVA 0%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 5.5,
                            account: '701055',
                            accountLabel: 'Ventes TVA 5,5%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 10,
                            account: '701010',
                            accountLabel: 'Ventes TVA 10%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 20,
                            account: '701020',
                            accountLabel: 'Ventes TVA 20%'
                        }
                    ]
                }
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: null,
                            rate: 0,
                            account: '701000',
                            accountLabel: 'Ventes TVA 0%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 5.5,
                            account: '701055',
                            accountLabel: 'Ventes TVA 5,5%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 10,
                            account: '701010',
                            accountLabel: 'Ventes TVA 10%'
                        },
                        {
                            countryCode: null,
                            regime: null,
                            rate: 20,
                            account: '701020',
                            accountLabel: 'Ventes TVA 20%'
                        }
                    ]
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            defaultValue: true
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false,
                    canEdit: false
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: [
                {
                    countryCode: null,
                    regime: null,
                    rate: 5.5,
                    account: '44571055',
                    accountLabel: 'TVA collectée 5,5%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 10,
                    account: '44571010',
                    accountLabel: 'TVA collectée 10%'
                },
                {
                    countryCode: null,
                    regime: null,
                    rate: 20,
                    account: '44571020',
                    accountLabel: 'TVA collectée 20%'
                }
            ]
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        documentRef: {
            visible: false,
            options: getDocumentRefOptions(),
            defaultValue: DocumentRefOptionEnum.INVOICE_FUNCTIONAL_ID
        },
        entryDescription: {
            options: axonautSellInvoiceEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption
        },
        entryLineDescription: {
            options: axonautSellInvoiceEntryLineDescriptionOptions,
            defaultOption: sellInvoiceEntryLineDescriptionThirdPartFieldOption
        }
    },

    buyInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'HA'
            },
            label: {
                defaultValue: 'Achats'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: ''
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Fournisseurs'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'FOURNISSEURS'
            },
            auxiliaryDynamic: {
                options: supplierThirdPartyTemplatingOptions,
                defaultOption: supplierThirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    canAdd: false,
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '471',
                        accountLabel: 'Charges à reclasser'
                    }
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '471'
                    },
                    label: {
                        defaultValue: 'Charges à reclasser'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            defaultValue: true
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false,
                    canEdit: false
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44566',
                accountLabel: 'TVA à reclasser'
            }
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        documentRef: {
            options: getDocumentRefOptions('Numéro de pièce généré par Axonaut'),
            defaultValue: DocumentRefOptionEnum.INVOICE_FUNCTIONAL_ID
        },
        entryDescription: {
            options: axonautBuyInvoiceEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption
        },
        entryLineDescription: {
            options: axonautBuyInvoiceEntryLineDescriptionOptions,
            defaultOption: buyInvoiceEntryLineDescriptionThirdPartFieldOption
        }
    },

    bankProcessorConfiguration: {
        journalOption: {
            visible: false,
            defaultValue: JournalOptionEnum.BY_PAYMENT_METHOD,
        },
        uniqueJournal: {
            visible: false,
            code: {
                defaultValue: 'BQ'
            },
            label: {
                defaultValue: 'Axonaut'
            }
        },
        client: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: ''
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Clients'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CLIENTS'
            },
            auxiliaryDynamic: {
                options: clientThirdPartyTemplatingOptions,
                defaultOption: clientThirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: ''
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Fournisseurs'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'FOURNISSEURS'
            },
            auxiliaryDynamic: {
                options: supplierThirdPartyTemplatingOptions,
                defaultOption: supplierThirdPartTemplatingNameFieldOption
            }
        },
        useSuggestedProviderAccounts: {
            defaultValue: true
        },
        methods: {
            unique: false,
            columns: {
                export: {
                    defaultValue: true
                }
            },
            defaultMethod: {
                name: null,
                export: true,
                journalCode: 'BQ',
                internalTransfer: {
                    account: null,
                    label: null
                },
                provider: {
                    account: '51299',
                    label: null
                }
            }
        },
        providerFee: {
            visible: false,
            account: {
                defaultValue: '62781'
            },
            label: {
                defaultValue: 'Autres frais et commissions sur prestations de services'
            }
        },
        deductibleVat20: {
            visible: false,
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        undefinedCredit: {
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        undefinedDebit: {
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        entryDescription: {
            options: axonautBankEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption,
        },
        entryLineDescription: {
            options: axonautBankEntryLineDescriptionOptions,
            defaultOption: bankEntryLineDescriptionDescriptionFieldOption,
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}