import { DescriptionOption, ThirdPartyTemplateOption } from "../field-type";

// ---- Description Parameters ----

export type PaymentDescriptionParameters = {
    withThirdPart: boolean;
}

export type SellInvoiceDescriptionParameters = {
    withThirdPart: boolean;
}

export type BuyInvoiceDescriptionParameters = {
    withThirdPart: boolean;
}












// ---- Entry Description ----

export const defaultEntryDescriptionEmptyFieldOption: DescriptionOption =
{
    label: 'Pas de libellé global',
    hasTemplate: false
};

export const defaultPaymentEntryDescriptionDynamicFieldOption: DescriptionOption =
{
    label: 'Personnalisé',
    hasTemplate: true,
    showTemplateField: true,
    templateValue: '{{ transaction.type }} • {{ transaction.documentRef }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
};

export const defaultInvoiceEntryDescriptionDynamicFieldOption: DescriptionOption =
{
    label: 'Personnalisé',
    hasTemplate: true,
    showTemplateField: true,
    templateValue: '{{ invoice.type }} • {{ invoice.documentRef }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
};

// ---- Entry Description - Payment ----

export const paymentEntryDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du client / fournisseur',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export function getPaymentEntryDescriptionFieldOptions(
    { withThirdPart }: PaymentDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryDescriptionEmptyFieldOption);
    if (withThirdPart) { descriptionOptions.push(paymentEntryDescriptionThirdPartFieldOption); }
    descriptionOptions.push(defaultPaymentEntryDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Description - Sell Invoices ----

export const sellInvoiceEntryDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du client',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export const sellInvoiceEntryDescriptionDocumentRefFieldOption: DescriptionOption =
{
    label: 'Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ invoice.documentRef }}',
};

export const sellInvoiceEntryDescriptionThirdPartAndDocumentRefFieldOption: DescriptionOption =
{
    label: 'Nom du client + Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }} • {{ invoice.documentRef }}',
};

export function getSellInvoiceEntryDescriptionFieldOptions(
    { withThirdPart }: SellInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryDescriptionEmptyFieldOption);
    if (withThirdPart) {
        descriptionOptions.push(sellInvoiceEntryDescriptionThirdPartFieldOption);
        descriptionOptions.push(sellInvoiceEntryDescriptionDocumentRefFieldOption);
        descriptionOptions.push(sellInvoiceEntryDescriptionThirdPartAndDocumentRefFieldOption);
    }
    descriptionOptions.push(defaultInvoiceEntryDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Description - Buy Invoices ----

export const buyInvoiceEntryDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du fournisseur',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export const buyInvoiceEntryDescriptionDocumentRefFieldOption: DescriptionOption =
{
    label: 'Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ invoice.documentRef }}',
};

export const buyInvoiceEntryDescriptionThirdPartAndDocumentRefFieldOption: DescriptionOption =
{
    label: 'Nom du fournisseur + Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }} • {{ invoice.documentRef }}',
};

export function getBuyInvoiceEntryDescriptionFieldOptions(
    { withThirdPart }: BuyInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryDescriptionEmptyFieldOption);
    if (withThirdPart) {
        descriptionOptions.push(buyInvoiceEntryDescriptionThirdPartFieldOption);
        descriptionOptions.push(buyInvoiceEntryDescriptionDocumentRefFieldOption);
        descriptionOptions.push(buyInvoiceEntryDescriptionThirdPartAndDocumentRefFieldOption);
    }
    descriptionOptions.push(defaultInvoiceEntryDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Description - Bank ----

export const bankEntryDescriptionDescriptionFieldOption: DescriptionOption =
{
    label: 'Libellé de l\'opération',
    hasTemplate: true,
    templateValue: '{{ transaction.description }}',
};

export function getBankEntryDescriptionFieldOptions(): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryDescriptionEmptyFieldOption);
    descriptionOptions.push(bankEntryDescriptionDescriptionFieldOption);
    descriptionOptions.push(defaultPaymentEntryDescriptionDynamicFieldOption);

    return descriptionOptions;
}











// ---- Entry Line Description ----

export const defaultEntryLineDescriptionStandardFieldOption: DescriptionOption =
{
    label: 'Libellé détaillé',
    hasTemplate: false
};

export const defaultPaymentEntryLineDescriptionDynamicFieldOption: DescriptionOption =
{
    label: 'Personnalisé',
    hasTemplate: true,
    showTemplateField: true,
    templateValue: '{{ transaction.type }} • {{ transaction.documentRef }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
};

export const defaultInvoiceEntryLineDescriptionDynamicFieldOption: DescriptionOption =
{
    label: 'Personnalisé',
    hasTemplate: true,
    showTemplateField: true,
    templateValue: '{{ invoice.type }} • {{ invoice.documentRef }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
};


// ---- Entry Line Description - Payment ----

export const paymentEntryLineDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du client / fournisseur',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export function getPaymentEntryLineDescriptionFieldOptions(
    { withThirdPart }: PaymentDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryLineDescriptionStandardFieldOption);
    if (withThirdPart) { descriptionOptions.push(paymentEntryLineDescriptionThirdPartFieldOption); }
    descriptionOptions.push(defaultPaymentEntryLineDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Line Description - Sell Invoices ----

export const sellInvoiceEntryLineDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du client',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export const sellInvoiceEntryLineDescriptionThirdPartAndDocumentRefFieldOption: DescriptionOption =
{
    label: 'Nom du client + Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }} • {{ invoice.documentRef }}',
};

export function getSellInvoiceEntryLineDescriptionFieldOptions(
    { withThirdPart }: SellInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryLineDescriptionStandardFieldOption);
    if (withThirdPart) {
        descriptionOptions.push(sellInvoiceEntryLineDescriptionThirdPartFieldOption);
        descriptionOptions.push(sellInvoiceEntryLineDescriptionThirdPartAndDocumentRefFieldOption);
    }
    descriptionOptions.push(defaultInvoiceEntryLineDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Line Description - Buy Invoices ----

export const buyInvoiceEntryLineDescriptionThirdPartFieldOption: DescriptionOption =
{
    label: 'Nom du fournisseur',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }}',
};

export const buyInvoiceEntryLineDescriptionThirdPartAndDocumentRefFieldOption: DescriptionOption =
{
    label: 'Nom du fournisseur + Numéro de pièce',
    hasTemplate: true,
    templateValue: '{{ thirdParty.mainName }} • {{ invoice.documentRef }}',
};

export function getBuyInvoiceEntryLineDescriptionFieldOptions(
    { withThirdPart }: BuyInvoiceDescriptionParameters
): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(defaultEntryLineDescriptionStandardFieldOption);
    if (withThirdPart) {
        descriptionOptions.push(buyInvoiceEntryLineDescriptionThirdPartFieldOption);
        descriptionOptions.push(buyInvoiceEntryLineDescriptionThirdPartAndDocumentRefFieldOption);
    }
    descriptionOptions.push(defaultInvoiceEntryLineDescriptionDynamicFieldOption);

    return descriptionOptions;
}

// ---- Entry Line Description - Bank ----

export const bankEntryLineDescriptionDescriptionFieldOption: DescriptionOption =
{
    label: 'Libellé de l\'opération',
    hasTemplate: true,
    templateValue: '{{ transaction.description }}',
};

export function getBankEntryLineDescriptionFieldOptions(): DescriptionOption[] {
    const descriptionOptions: DescriptionOption[] = [];

    descriptionOptions.push(bankEntryLineDescriptionDescriptionFieldOption);
    descriptionOptions.push(defaultPaymentEntryLineDescriptionDynamicFieldOption);

    return descriptionOptions;
}











// Third Part

export const clientThirdPartTemplatingNameFieldOption: ThirdPartyTemplateOption =
{
    label: 'Nom du client',
    hasDynamic: true,
    showAdvancedTemplateField: false,
    auxiliaryAccountDynamic: '{{ thirdParty.mainName | normalize | upper | left(8) }}',
    auxiliaryLabelDynamic: '{{ thirdParty.mainName }}'
};

export const supplierThirdPartTemplatingNameFieldOption: ThirdPartyTemplateOption =
{
    label: 'Nom du fournisseur',
    hasDynamic: true,
    showAdvancedTemplateField: false,
    auxiliaryAccountDynamic: '{{ thirdParty.mainName | normalize | upper | left(8) }}',
    auxiliaryLabelDynamic: '{{ thirdParty.mainName }}'
};

export const thirdPartTemplatingAdvancedFieldOption: ThirdPartyTemplateOption =
{
    label: 'Avancé',
    hasDynamic: true,
    showAdvancedTemplateField: true,
    auxiliaryAccountDynamic: '{{ thirdParty.mainName | normalize | upper | left(8) }}',
    auxiliaryLabelDynamic: '{{ thirdParty.mainName }}'
};

export const clientThirdPartyTemplatingOptions: ThirdPartyTemplateOption[] = [
    clientThirdPartTemplatingNameFieldOption,
    thirdPartTemplatingAdvancedFieldOption
]

export const supplierThirdPartyTemplatingOptions: ThirdPartyTemplateOption[] = [
    supplierThirdPartTemplatingNameFieldOption,
    thirdPartTemplatingAdvancedFieldOption
]