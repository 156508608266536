import { InvoiceGenerationOptionEnum } from "~modules/connectors/constants/Invoice-generation.option";
import { AnalyticRuleTypeEnum } from "~modules/connectors/constants/analytic-rule-type.option";
import { DocumentRefOptionEnum, DocumentRefOptionEnumType } from "~modules/connectors/constants/document-ref.option";
import { InvoiceAmountDifferenceOptionEnum } from "~modules/connectors/constants/invoice-amount-difference.option";
import { JournalOptionEnum } from "~modules/connectors/constants/journal.options";
import { ReverseChargeAggregateOptionEnum } from "~modules/connectors/constants/reverse-charge-aggregate.options";
import { VatRegimeOptionEnum } from "~modules/connectors/constants/vat-regime.option";
import { AccountingEntryNumberModeEnum } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';
import { defaultEntryDescriptionEmptyFieldOption } from "./transformation-fields/default-transformation-fields";

export const defaultJournalCodeMaxLength = 5;
export const defaultJournalLabelMaxLength = 35;
export const defaultMainAccountMaxLength = 12;
export const defaultAuxiliaryAccountMaxLength = 20;
export const defaultAccountLabelMaxLength = 70;
export const defaultDynamicConfigMaxLength = 65535;
export const defaultPourcentageMinValue = 0.01;
export const defaultPourcentageMaxValue = 99.99;
export const pourcentageMaxValue = 100;
export const defaultPourcentagePatternValue = new RegExp(/^\d{1,3}([.,]\d{1,2})?$/, "i");


export type FieldType<Type> = {
    defaultValue: Type,
    visible?: boolean
};

export type StringFieldType = FieldType<string> & {
    maxLength?: number;
};

export type NumberFieldType = FieldType<number> & {
    minValue?: number;
    maxValue?: number;
    patternValue?: RegExp;
};

export type DateRangeType = {
    visible: boolean,
    fromDate?: FieldType<unknown>,
    toDate?: FieldType<unknown>
}

export type FileType = {
    visible: boolean,
    mimeType: string|null
}

export type JournalSectionFieldsConfiguration = {
    visible?: boolean;
    label: StringFieldType;
    code: StringFieldType;
}

export type AccountSectionFieldsConfiguration = {
    visible?: boolean;
    account: StringFieldType;
    label: StringFieldType;
}

export type ThirdPartyAccountSectionFieldsConfiguration = {
    visible?: boolean;
    useSuggestedAccounts: FieldType<boolean>;
    account: StringFieldType;
    label: StringFieldType;
    isDynamic: FieldType<boolean>;
    auxiliaryAccountStatic: StringFieldType;
    auxiliaryLabelStatic: StringFieldType;
    auxiliaryDynamic: {
        visible?: boolean;
        accountMaxLength?: number;
        labelMaxLength?: number;
        options: ThirdPartyTemplateOption[];
        defaultOption: ThirdPartyTemplateOption;
    }
}

export type RunFieldsConfiguration = {
    runCursor: DateRangeType;
    file: FileType;
}

export type AccountingEntriesFieldsConfiguration = {
    toolLabel: string,
    paymentProcessorConfiguration?: PaymentFieldsConfiguration,
    sellInvoiceProcessorConfiguration?: InvoiceFieldsConfiguration,
    buyInvoiceProcessorConfiguration?: InvoiceFieldsConfiguration,
    bankProcessorConfiguration?: PaymentFieldsConfiguration,
    analyticProcessorConfiguration?: AnalyticFieldsConfiguration
}

export type DownloadFieldsConfiguration = {
    toolLabel: string;
    generatePartialLettering: FieldType<boolean>;
    accountingEntryNumberMode: FieldType<AccountingEntryNumberModeEnum>;
}

export type FormArrayColumnConfiguration<Type> = {
    canEdit?: boolean;
    defaultValue?: Type;
}

export type PaymentMethodAccountFieldsConfiguration<Type> = {
    account: Type;
    label: string|null;
}

export type PaymentMethodAccountWithAuxiliaryFieldsConfiguration<Type> = {
    account: Type;
    label: string|null;
    auxiliaryAccount: Type;
    auxiliaryLabel: string|null;
}

export type PaymentMethodFieldsConfiguration = {
    name: string|null;
    export: boolean|null;
    journalCode?: string|null;
    provider?: PaymentMethodAccountFieldsConfiguration<string>;
    internalTransfer?: PaymentMethodAccountFieldsConfiguration<string|null>;
    bankSupplier?: PaymentMethodAccountWithAuxiliaryFieldsConfiguration<string|null>;
}

export type PaymentMethodsSectionFieldsConfiguration = {
    unique?: boolean;
    canAdd?: boolean;
    canDelete?: boolean;
    paymentMethodOptions?: string[];
    internalTransferVisibleFor?: string[];
    bankSupplierVisibleFor?: (string|null)[];
    columns?: {
        name?: FormArrayColumnConfiguration<string>,
        export?: FormArrayColumnConfiguration<boolean>,
        journalCode?: FormArrayColumnConfiguration<string>,
        provider?: FormArrayColumnConfiguration<PaymentMethodAccountFieldsConfiguration<string>>,
        internalTransfer?: FormArrayColumnConfiguration<PaymentMethodAccountFieldsConfiguration<string|null>>,
        bankSupplier?: FormArrayColumnConfiguration<PaymentMethodAccountWithAuxiliaryFieldsConfiguration<string|null>>
    }
    defaultMethod: PaymentMethodFieldsConfiguration;
    methods?: PaymentMethodFieldsConfiguration[];
}

export type VatAccountSectionFieldsConfiguration = {
    countryCode: string|null;
    regime: VatRegimeOptionEnum|null;
    rate: number|null;
    account: string;
    accountLabel: string;
}

export type VatAccountsSectionFieldsConfiguration = {
    canAdd?: boolean;
    canDelete?: boolean;
    columns?: {
        countryCode?: FormArrayColumnConfiguration<string> & { visible?: boolean },
        regime?: FormArrayColumnConfiguration<VatRegimeOptionEnum> & { visible?: boolean },
        rate?: FormArrayColumnConfiguration<number> & { visible?: boolean },
        account?: FormArrayColumnConfiguration<string>,
        accountLabel?: FormArrayColumnConfiguration<string>
    }
    defaultAccount: VatAccountSectionFieldsConfiguration;
    accounts?: VatAccountSectionFieldsConfiguration[];
}

export type AnalyticAccountRuleSectionFieldsConfiguration = {
    type: AnalyticRuleTypeEnum;
    account: string|null;
    axis: string;
    tag: string;
    value: number;
}

export type AnalyticAccountRulesSectionFieldsConfiguration = {
    visible?: boolean;
    canAdd?: boolean;
    canDelete?: boolean;
    columns?: {
        type?: FormArrayColumnConfiguration<AnalyticRuleTypeEnum>,
        account?: FormArrayColumnConfiguration<string>,
        axis?: FormArrayColumnConfiguration<string>,
        tag?: FormArrayColumnConfiguration<string>,
        value?: FormArrayColumnConfiguration<number>
    }
    accounts?: AnalyticAccountRuleSectionFieldsConfiguration[];
}

export type PaymentFieldsConfiguration = {
    journalOption: FieldType<JournalOptionEnum>;
    uniqueJournal: JournalSectionFieldsConfiguration;
    client: ThirdPartyAccountSectionFieldsConfiguration;
    supplier: ThirdPartyAccountSectionFieldsConfiguration;
    useSuggestedProviderAccounts: FieldType<boolean>;
    methods: PaymentMethodsSectionFieldsConfiguration;
    providerFee: AccountSectionFieldsConfiguration;
    deductibleVat20: AccountSectionFieldsConfiguration;
    reverseCharge: {
        visible?: boolean;
        has: FieldType<boolean>;
        aggregateOption: FieldType<ReverseChargeAggregateOptionEnum>;
        collectedIntracomVat: AccountSectionFieldsConfiguration;
        deductibleIntracomVat: AccountSectionFieldsConfiguration;
        vatRate: NumberFieldType;
    };
    undefinedCredit: AccountSectionFieldsConfiguration;
    undefinedDebit: AccountSectionFieldsConfiguration;
    entryDescription: DescriptionFieldsConfiguration;
    entryLineDescription: DescriptionFieldsConfiguration;
}

export type InvoiceFieldsConfiguration = {
    journal: JournalSectionFieldsConfiguration;
    thirdPartyAccount: ThirdPartyAccountSectionFieldsConfiguration;
    generation: {
        visible?: boolean;
        useSuggestedProductsAccounts: FieldType<boolean>;
        option: FieldType<InvoiceGenerationOptionEnum>;
        oneLinePerInvoiceLine?: {
            productVats: VatAccountsSectionFieldsConfiguration;
        },
        oneLinePerVatRate?: {
            productVats: VatAccountsSectionFieldsConfiguration;
        }
        oneGlobalLine?: {
            product: AccountSectionFieldsConfiguration;
        }
    }
    useSuggestedVatsAccounts: FieldType<boolean>;
    collectedVats: VatAccountsSectionFieldsConfiguration;
    amountDifferenceOption: FieldType<InvoiceAmountDifferenceOptionEnum>;
    debitAdjustment: AccountSectionFieldsConfiguration;
    creditAdjustment: AccountSectionFieldsConfiguration;
    documentRef: FieldType<DocumentRefOptionEnum> & {
        options: DocumentRefOptionEnumType[];
    }
    entryDescription: DescriptionFieldsConfiguration;
    entryLineDescription: DescriptionFieldsConfiguration;
}

export type DescriptionFieldsConfiguration = {
    visible?: boolean;
    options: DescriptionOption[]; // dynamic should always be the last element
    defaultOption: DescriptionOption;
    templateMaxLength?: number;
};

export type DescriptionOption = {
    label: string;
    hasTemplate: boolean;
    showTemplateField?: boolean;
    templateValue?: string;
}

export type AnalyticFieldsConfiguration = {
    visible?: boolean;
    isEnabled: FieldType<boolean>;
    accountRules: AnalyticAccountRulesSectionFieldsConfiguration
}

export type ThirdPartyTemplateOption = {
    label: string;
    hasDynamic: boolean;
    showAdvancedTemplateField: boolean;
    auxiliaryAccountDynamic: string;
    auxiliaryLabelDynamic: string|null;
}

// ------- OVERRIDE -------

export type AccountingEntriesOverrideFieldsConfiguration = {
    paymentProcessorConfiguration: PaymentOverrideFieldsConfiguration,
    sellInvoiceProcessorConfiguration: InvoiceOverrideFieldsConfiguration,
    buyInvoiceProcessorConfiguration: InvoiceOverrideFieldsConfiguration,
    bankProcessorConfiguration: PaymentOverrideFieldsConfiguration,
    analyticProcessorConfiguration: AnalyticOverrideFieldsConfiguration
}

export type PaymentOverrideFieldsConfiguration = {
    entryDescription: DescriptionOverrideFieldsConfiguration;
}

export type InvoiceOverrideFieldsConfiguration = {
    entryDescription: DescriptionOverrideFieldsConfiguration;
}

export type DescriptionOverrideFieldsConfiguration = {
    disable?: boolean;
};

export type AnalyticOverrideFieldsConfiguration = {
    disable?: boolean;
}

export function compileAccountingEntriesFieldsConfiguration(
    configuration: AccountingEntriesFieldsConfiguration,
    override?: AccountingEntriesOverrideFieldsConfiguration
): AccountingEntriesFieldsConfiguration {
    // create deep copy
    const res: AccountingEntriesFieldsConfiguration = JSON.parse(JSON.stringify(configuration));
    if (override) {
        // payment
        overridePaymentFieldsConfiguration(res.paymentProcessorConfiguration, override.paymentProcessorConfiguration);
        // sell invoice
        overrideInvoiceFieldsConfiguration(res.sellInvoiceProcessorConfiguration, override.sellInvoiceProcessorConfiguration);
        // buy invoice
        overrideInvoiceFieldsConfiguration(res.buyInvoiceProcessorConfiguration, override.buyInvoiceProcessorConfiguration);
        // bank
        overridePaymentFieldsConfiguration(res.bankProcessorConfiguration, override.bankProcessorConfiguration);
        // analytic
        overrideAnalyticFieldsConfiguration(res.analyticProcessorConfiguration, override.analyticProcessorConfiguration);
    }
    return res;
}

function overridePaymentFieldsConfiguration(res?: PaymentFieldsConfiguration, override?: PaymentOverrideFieldsConfiguration): void {
    if (res && override) {
        if (res.entryDescription && override.entryDescription && override.entryDescription.disable && override.entryDescription.disable === true) {
            res.entryDescription.visible = false;
            res.entryDescription.defaultOption = defaultEntryDescriptionEmptyFieldOption;
        }
    }
}

function overrideInvoiceFieldsConfiguration(res?: InvoiceFieldsConfiguration, override?: InvoiceOverrideFieldsConfiguration): void {
    if (res && override) {
        if (res.entryDescription && override.entryDescription && override.entryDescription.disable && override.entryDescription.disable === true) {
            res.entryDescription.visible = false;
            res.entryDescription.defaultOption = defaultEntryDescriptionEmptyFieldOption;
        }
    }
}

function overrideAnalyticFieldsConfiguration(res?: AnalyticFieldsConfiguration, override?: AnalyticOverrideFieldsConfiguration): void {
    if (res && override) {
        if (override.disable && override.disable === true) {
            res.visible = false;
            res.isEnabled.defaultValue = false;
            res.accountRules.accounts = [];
        }
    }
}