<form [formGroup]="formGroup">

  <mat-form-field class="full-width">
    <mat-label>Options du connecteur</mat-label>
    <mat-select formControlName="option"
    [compareWith]="compareSelectionObjects">
      <mat-option [value]="configurationOption"
        *ngFor="let configurationOption of configurationOptions">{{configurationOption.label}}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="formGroup.value.option != null">
    <ng-container *ngIf="formGroup.value.option.hasPaymentConfiguration === true">
      <h3>Encaissements</h3>
      <app-payment-processor-configuration
       paymentType='PAYMENT'
       [toolLabel]="fieldsConfig?.toolLabel"
       [fieldsConfiguration]="fieldsConfig?.paymentProcessorConfiguration"
       [paymentConfiguration]="paymentConfiguration"
       #paymentProcessorConfiguration
       ></app-payment-processor-configuration>
    </ng-container>
    <ng-container *ngIf="formGroup.value.option.hasSellInvoiceConfiguration === true">
      <h3>Ventes</h3>
      <app-invoice-processor-configuration
       invoiceDirection='SELL'
       [toolLabel]="fieldsConfig?.toolLabel"
       [fieldsConfiguration]="fieldsConfig?.sellInvoiceProcessorConfiguration"
       [invoiceConfiguration]="sellInvoiceConfiguration"
       #sellInvoiceProcessorConfiguration
       ></app-invoice-processor-configuration>
    </ng-container>
    <ng-container *ngIf="formGroup.value.option.hasBuyInvoiceConfiguration === true">
      <h3>Achats</h3>
      <app-invoice-processor-configuration
       invoiceDirection='BUY'
       [toolLabel]="fieldsConfig?.toolLabel"
       [fieldsConfiguration]="fieldsConfig?.buyInvoiceProcessorConfiguration"
       [invoiceConfiguration]="buyInvoiceConfiguration"
       #buyInvoiceProcessorConfiguration
       ></app-invoice-processor-configuration>
    </ng-container>
    <ng-container *ngIf="formGroup.value.option.hasBankConfiguration === true">
      <h3>Banques</h3>
      <app-payment-processor-configuration
       paymentType='BANK'
       [toolLabel]="fieldsConfig?.toolLabel"
       [fieldsConfiguration]="fieldsConfig?.bankProcessorConfiguration"
       [paymentConfiguration]="bankConfiguration"
       #bankProcessorConfiguration
       ></app-payment-processor-configuration>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="fieldsConfig?.analyticProcessorConfiguration != null && fieldsConfig?.analyticProcessorConfiguration?.visible !== false">
    <br />
    <h3><mat-icon>bookmark</mat-icon>&nbsp;Analytique</h3>
    <app-analytic-processor-configuration
      [toolLabel]="fieldsConfig?.toolLabel"
      [fieldsConfiguration]="fieldsConfig?.analyticProcessorConfiguration"
      [analyticConfiguration]="analyticConfiguration"
    ></app-analytic-processor-configuration>
  </ng-container>
</form>