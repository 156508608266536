<form [formGroup]="formGroup">
    <fieldset *ngIf="fieldsConfig?.uniqueJournal?.visible !== false || (fieldsConfig?.journalOption?.visible !== false && formGroup.value.journalOption === 'UNIQUE')">
      <legend>Journal</legend>
      <div class="row" *ngIf="fieldsConfig?.journalOption?.visible !== false">
        <mat-form-field class="full-width">
          <mat-label>Répartition des journaux</mat-label>
          <mat-select formControlName="journalOption" [compareWith]="compareFunction">
            <mat-option *ngFor="let option of journalOptions" [value]="option.value">{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="third-width" *ngIf="fieldsConfig?.uniqueJournal?.code?.visible !== false && formGroup.value.journalOption === 'UNIQUE'">
        <mat-label>Code Journal</mat-label>
        <input matInput formControlName="uniqueJournalCode" [placeholder]="fieldsConfig?.uniqueJournal?.code?.defaultValue || ''">
      </mat-form-field>
      <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.uniqueJournal?.label?.visible !== false && formGroup.value.journalOption === 'UNIQUE'">
        <mat-label>Libellé Journal</mat-label>
        <input matInput formControlName="uniqueJournalLabel" [placeholder]="fieldsConfig?.uniqueJournal?.label?.defaultValue || ''">
      </mat-form-field>
    </fieldset>

    <fieldset *ngIf="fieldsConfig?.client?.visible !== false || fieldsConfig?.supplier?.visible !== false">
      <legend>Tiers (clients / fournisseurs)</legend>
      <ng-container *ngIf="fieldsConfig?.client?.visible !== false">
        <app-third-party-processor-configuration
        [fieldsConfiguration]="fieldsConfig?.client"
        [thirdPartyAccount]="paymentConfig?.clientAccount"
        [toolLabel]="toolLabel"
        [thirdPartyType]="'CLIENT'"
        #clientAccount
        ></app-third-party-processor-configuration>
      </ng-container>
      <ng-container *ngIf="fieldsConfig?.supplier?.visible !== false">
        <app-third-party-processor-configuration
        [fieldsConfiguration]="fieldsConfig?.supplier"
        [thirdPartyAccount]="paymentConfig?.supplierAccount"
        [toolLabel]="toolLabel"
        [thirdPartyType]="'SUPPLIER'"
        #supplierAccount
        ></app-third-party-processor-configuration>
      </ng-container>
    </fieldset>

    <fieldset>
      <legend *ngIf="fieldsConfig?.methods?.unique === true">Banque / Instrument financier</legend>
      <legend *ngIf="fieldsConfig?.methods?.unique === false">Encaissements</legend>
      <app-payment-method-configuration
        [fieldsConfiguration]="fieldsConfig?.methods"
        [methods]="paymentConfig?.methods"
        [journalOption]="formGroup.value.journalOption"
        [toolLabel]="toolLabel"
        addLabel="Ajouter une méthode de paiement"
        #methods
      ></app-payment-method-configuration>
    </fieldset>

    <fieldset *ngIf="fieldsConfig?.providerFee?.visible !== false || fieldsConfig?.deductibleVat20?.visible !== false || fieldsConfig?.reverseCharge?.visible !== false">
      <legend>Frais et TVA sur frais</legend>
      <div class="row" *ngIf="fieldsConfig?.providerFee?.visible !== false">
        <mat-form-field class="third-width" *ngIf="fieldsConfig?.providerFee?.account?.visible !== false">
          <mat-label>Compte de frais sur transactions</mat-label>
          <input matInput formControlName="providerFeeAccount" [placeholder]="fieldsConfig?.providerFee?.account?.defaultValue || ''">
        </mat-form-field>
        <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.providerFee?.label?.visible !== false">
          <mat-label>Libellé du compte de frais sur transaction</mat-label>
          <input matInput formControlName="providerFeeAccountLabel" [placeholder]="fieldsConfig?.providerFee?.label?.defaultValue || ''">
        </mat-form-field>
      </div>
      <div class="row" *ngIf="fieldsConfig?.deductibleVat20?.visible !== false">
        <mat-form-field class="third-width" *ngIf="fieldsConfig?.deductibleVat20?.account?.visible !== false">
          <mat-label>Compte de TVA Déductible 20%</mat-label>
          <input matInput formControlName="deductibleVat20Account" [placeholder]="fieldsConfig?.deductibleVat20?.account?.defaultValue || ''">
        </mat-form-field>
        <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.deductibleVat20?.label?.visible !== false">
          <mat-label>Libellé du compte de TVA Déductible 20%</mat-label>
          <input matInput formControlName="deductibleVat20AccountLabel" [placeholder]="fieldsConfig?.deductibleVat20?.label?.defaultValue || ''">
        </mat-form-field>
      </div>
      <ng-container *ngIf="fieldsConfig?.reverseCharge?.visible !== false">
        <div class="row">
          <mat-slide-toggle formControlName="hasReverseCharge" *ngIf="fieldsConfig?.reverseCharge?.has?.visible !== false">
            <div class="text-and-icon-container">
              <span>Autoliquider la TVA sur frais</span>&nbsp;
              <mat-icon 
              [matTooltipPosition]="'after'"
              matTooltip="{{toolLabel}} ne facture pas de TVA sur ses frais car il n'est pas domicilié en France. Vous pouvez choisir d'autoliquider la TVA sur frais en cochant cette case.">
                help_outlined
              </mat-icon>
            </div>
          </mat-slide-toggle>
        </div>
        <ng-container *ngIf="formGroup.value.hasReverseCharge === true">
          <div class="row">
            <mat-form-field class="full-width" *ngIf="fieldsConfig?.reverseCharge?.aggregateOption?.visible !== false">
              <mat-label>Option d'autoliquidation</mat-label>
              <mat-select formControlName="reverseChargeAggregateOption" [compareWith]="compareFunction">
                <mat-option *ngFor="let option of reverseChargeAggregateOptions" [value]="option.value">{{option.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row" *ngIf="fieldsConfig?.reverseCharge?.collectedIntracomVat?.visible !== false">
            <mat-form-field class="third-width" *ngIf="fieldsConfig?.reverseCharge?.collectedIntracomVat?.account?.visible !== false">
              <mat-label>Compte TVA Collectée Intracommunautaire</mat-label>
              <input matInput formControlName="reverseChargeCollectedIntracomVatAccount" [placeholder]="fieldsConfig?.reverseCharge?.collectedIntracomVat?.account?.defaultValue || ''">
            </mat-form-field>
            <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.reverseCharge?.collectedIntracomVat?.visible !== false">
              <mat-label>Libellé compte TVA Collectée Intracommunautaire</mat-label>
              <input matInput formControlName="reverseChargeCollectedIntracomVatAccountLabel" [placeholder]="fieldsConfig?.reverseCharge?.collectedIntracomVat?.label?.defaultValue || ''">
            </mat-form-field>
          </div>
          <div class="row" *ngIf="fieldsConfig?.reverseCharge?.deductibleIntracomVat?.visible !== false">
            <mat-form-field class="third-width" *ngIf="fieldsConfig?.reverseCharge?.deductibleIntracomVat?.account?.visible !== false">
              <mat-label>Compte TVA Déductible Intracommunautaire</mat-label>
              <input matInput formControlName="reverseChargeDeductibleIntracomVatAccount" [placeholder]="fieldsConfig?.reverseCharge?.deductibleIntracomVat?.account?.defaultValue || ''">
            </mat-form-field>    
            <mat-form-field class="two-thirds-width" *ngIf="fieldsConfig?.reverseCharge?.deductibleIntracomVat?.visible !== false">
              <mat-label>Libellé compte TVA Déductible Intracommunautaire</mat-label>
              <input matInput formControlName="reverseChargeDeductibleIntracomVatAccountLabel" [placeholder]="fieldsConfig?.reverseCharge?.deductibleIntracomVat?.label?.defaultValue || ''">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="third-width" *ngIf="fieldsConfig?.reverseCharge?.vatRate?.visible !== false">
              <mat-label>Taux de TVA</mat-label>
              <input matInput formControlName="reverseChargeVatRate" type="number" [placeholder]="fieldsConfig?.reverseCharge?.vatRate?.defaultValue?.toString() || ''">
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </fieldset>

    <fieldset *ngIf="fieldsConfig?.description?.visible !== false">
      <legend>Personnalisation des écritures</legend>
      <div class="row">
        <mat-form-field class="full-width">
          <mat-label>Options de personnalisation</mat-label>
          <mat-select  formControlName="descriptionOption"
          [compareWith]="compareSelectionObjects">
            <mat-option *ngFor="let option of fieldsConfig?.description?.options" [value]="option">{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field *ngIf="this.formGroup.get('descriptionOption')?.value.showDescriptionTemplateField === true && fieldsConfig?.description?.descriptionTemplate?.visible !== false" class="full-width">
          <mat-label>Libellé d'écriture personnalisé</mat-label>
          <textarea matInput
          (keypress)="avoidLineBreak($event)"
          formControlName="descriptionTemplate"
          cdkTextareaAutosize
          #descriptionTemplateAutosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5">
          </textarea>
        </mat-form-field>
      </div>
    </fieldset>
</form>