export type VatRegimeOptionType = { label: string, tooltip: string, value: string|null };

export enum VatRegimeOptionEnum {
    CLASSIC = 'CLASSIC',
    MARGIN = 'MARGIN'
}

export const vatRegimeOptions: VatRegimeOptionType[] = [
    {
        label: 'C',
        tooltip: 'TVA classique',
        value: VatRegimeOptionEnum.CLASSIC
    },
    {
        label: 'M',
        tooltip: 'TVA sur marge',
        value: VatRegimeOptionEnum.MARGIN
    }
]