export type DocumentRefOptionEnumType = { label: string, value: DocumentRefOptionEnum};

export enum DocumentRefOptionEnum {
    INVOICE_FUNCTIONAL_ID = 'INVOICE_FUNCTIONAL_ID',
    BUYER_REFERENCE_ID = 'BUYER_REFERENCE_ID'
}

const invoiceFunctionalIdDocumentRefOption: DocumentRefOptionEnumType = {
    label: 'Numéro de pièce d\'origine',
    value: DocumentRefOptionEnum.INVOICE_FUNCTIONAL_ID
}

export function getDocumentRefOptions(buyerReferenceLabel?: string): DocumentRefOptionEnumType[] {
    const result: DocumentRefOptionEnumType[] = [invoiceFunctionalIdDocumentRefOption];
    if (buyerReferenceLabel != null) {
        result.push({
            label: buyerReferenceLabel,
            value: DocumentRefOptionEnum.BUYER_REFERENCE_ID
        })
    }
    return result;
}