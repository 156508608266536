import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { CurrentCompanyService } from '~modules/company/services/current-company.service';
import { ThirdPartsType } from '~modules/thirdparts/common/entities/third-parts-type.constant';

@Injectable()
export class CompanyOfferThirdPartsGuard {

    constructor(
        private currentCompanyService: CurrentCompanyService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        // Only execute guard on last sub-route
        if (route.children != null && route.children.length > 0) {
            return of(true);
        }

        const thirdPart = route.data['thirdPart'] as ThirdPartsType || null;

        return this.currentCompanyService.currentCompanyOfferCanAccess$(thirdPart).pipe(
            tap(canAccess => {
                if (!canAccess) {
                    this.router.navigate(['/welcome']);
                }
            }),
        );
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
