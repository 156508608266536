<form [formGroup]="formGroup">
  <ng-container  *ngIf="fieldsConfig?.visible !== false">
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>{{descriptionOptionLabel}}</mat-label>
        <mat-select formControlName="descriptionOption"
        [compareWith]="compareSelectionObjects">
          <mat-option *ngFor="let option of fieldsConfig?.options" [value]="option">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field *ngIf="formGroup.value.descriptionOption?.showTemplateField != null && formGroup.value.descriptionOption?.showTemplateField === true" class="full-width">
        <mat-label>{{descriptionTemplateLabel}}</mat-label>
        <textarea matInput
        (keypress)="avoidLineBreak($event)"
        formControlName="template"
        cdkTextareaAutosize
        #clientAccountLabelAutosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5">
        </textarea>
      </mat-form-field>
    </div>
  </ng-container>
</form>