import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentEntryDescriptionFieldOptions, getPaymentEntryLineDescriptionFieldOptions, defaultEntryDescriptionEmptyFieldOption, defaultEntryLineDescriptionStandardFieldOption, clientThirdPartyTemplatingOptions, clientThirdPartTemplatingNameFieldOption, supplierThirdPartyTemplatingOptions, thirdPartTemplatingAdvancedFieldOption } from './default-transformation-fields';

const gocardlessPaymentEntryDescriptionOptions: DescriptionOption[] = getPaymentEntryDescriptionFieldOptions({
    withThirdPart: true
});

const gocardlessPaymentEntryLineDescriptionOptions: DescriptionOption[] = getPaymentEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

export const gocardlessTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Gocardless',

    paymentProcessorConfiguration: {
        journalOption: {
            visible: false,
            defaultValue: JournalOptionEnum.UNIQUE,
        },
        uniqueJournal: {
            code: {
                defaultValue: 'GOC'
            },
            label: {
                defaultValue: 'Gocardless'
            }
        },
        client: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'GOCARDLESS'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'GOCARDLESS'
            },
            auxiliaryDynamic: {
                options: clientThirdPartyTemplatingOptions,
                defaultOption: clientThirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            visible: false,
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'GOCARDLESS'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'GOCARDLESS'
            },
            auxiliaryDynamic: {
                options: supplierThirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingAdvancedFieldOption
            }
        },
        useSuggestedProviderAccounts: {
            visible: false,
            defaultValue: false
        },
        methods: {
            unique: true,
            defaultMethod: {
                name: null,
                export: true,
                journalCode: null,
                internalTransfer: {
                    account: '581',
                    label: 'Virement interne GOCARDLESS'
                },
                provider: {
                    account: '5171',
                    label: 'GOCARDLESS'
                },
                bankSupplier: {
                    account: '401',
                    label: 'Fournisseurs',
                    auxiliaryAccount: 'GOCARDLESS',
                    auxiliaryLabel: 'GOCARDLESS'
                }
            }
        },
        providerFee: {
            account: {
                defaultValue: '62781'
            },
            label: {
                defaultValue: 'Autres frais et commissions sur prestations de services'
            }
        },
        deductibleVat20: {
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        undefinedCredit: {
            visible: false,
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        undefinedDebit: {
            visible: false,
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        entryDescription: {
            options: gocardlessPaymentEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption,
        },
        entryLineDescription: {
            options: gocardlessPaymentEntryLineDescriptionOptions,
            defaultOption: defaultEntryLineDescriptionStandardFieldOption,
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}