import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentDescriptionFieldOptions, thirdPartTemplatingNameFieldOption, thirdPartyTemplatingOptions } from './default-transformation-fields';

const paypalPaymentDescriptionOptions: DescriptionOption[] = getPaymentDescriptionFieldOptions({
    withThirdPart: true
});

export const paypalTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Paypal',

    paymentProcessorConfiguration: {
        journalOption: {
            visible: false,
            defaultValue: JournalOptionEnum.UNIQUE,
        },
        uniqueJournal: {
            code: {
                defaultValue: 'PAY'
            },
            label: {
                defaultValue: 'Paypal'
            }
        },
        client: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'PAYPAL'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'PAYPAL'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'PAYPAL'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'PAYPAL'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        methods: {
            unique: true,
            defaultMethod: {
                name: null,
                export: true,
                journalCode: null,
                internalTransfer: {
                    account: '581',
                    label: 'Virement interne PAYPAL'
                },
                provider: {
                    account: '5171',
                    label: 'PAYPAL'
                }
            }
        },
        providerFee: {
            account: {
                defaultValue: '62781'
            },
            label: {
                defaultValue: 'Autres frais et commissions sur prestations de services'
            }
        },
        deductibleVat20: {
            visible: false,
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        description: {
            options: paypalPaymentDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ transaction.type }} • {{ transaction.subTypeId | default(transaction.transactionId) }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}