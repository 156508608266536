import { AccountingEntryNumberModeEnum } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

export const inqomJournalsApiFieldsConfiguration : DownloadFieldsConfiguration = {
    toolLabel: 'inqom_journals_api',
    // unused
    accountingEntryNumberMode: {
        defaultValue: AccountingEntryNumberModeEnum.NONE
    },
    // unused
    generatePartialLettering: {
        defaultValue: false
    }
}