import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class ArtifactFilesService {

    constructor(
        private readonly http: HttpClient
    ) {}

    public static getPath() {
        return `/api/artifact-files`;
    }
    getBlobById(id: string): Observable<HttpEvent<Blob>> {
        const url = `${ArtifactFilesService.getPath()}/${id}`;
        return this.http.get(url, {reportProgress: true, responseType: 'blob', observe: 'events'});
    }
}