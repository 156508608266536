import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { AccountingEntryNumberModeEnum, AccountingEntryNumberModeService, AccountingEntryNumberModeType } from '../../../common/services/accounting-number-mode.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-load-dex-download-configuration',
  templateUrl: './load-dex-download-configuration.component.html',
  standalone: true,
  providers: [
    AccountingEntryNumberModeService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class LoadDexDownloadConfigurationComponent {
  formGroup!: FormGroup;
  configuration?: DownloadFieldsConfiguration;
  accountingNumberModeList!: AccountingEntryNumberModeType[];

  @Input()
  set config (config: DownloadFieldsConfiguration | null) {
    if (config != null) {
      this.configuration = config;
      this.formGroup.get('generatePartialLettering')?.setValue(config.generatePartialLettering.defaultValue);
      this.formGroup.get('accountingEntryNumberMode')?.setValue(config.accountingEntryNumberMode.defaultValue);
    }
  }

  get documentationImportUrl() {
    return environment.documentation.dex.import;
  }

  constructor(
    private readonly fb: FormBuilder,
    readonly accountingEntryNumberModeService: AccountingEntryNumberModeService
  ) {
    this.accountingNumberModeList = accountingEntryNumberModeService.getAll();
    this.formGroup = fb.group({
      folderId: [null, [Validators.required, Validators.maxLength(8)]],
      generatePartialLettering: [false, Validators.required],
      accountingEntryNumberMode: [AccountingEntryNumberModeEnum.NONE, Validators.required]
    });
  }
}
