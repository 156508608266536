import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { AccountingEntryNumberModeEnum, AccountingEntryNumberModeService, AccountingEntryNumberModeType } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';

@Component({
  selector: 'app-load-inqom-zip-fec-download-configuration',
  templateUrl: './load-inqom-zip-fec-download-configuration.component.html',
  styleUrls: ['./load-inqom-zip-fec-download-comfiguration.component.scss'],
  standalone: true,
  providers: [
    AccountingEntryNumberModeService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class LoadInqomZipFecDownloadConfigurationComponent {
  formGroup!: FormGroup;
  accountingNumberModeList!: AccountingEntryNumberModeType[];
  configuration?: DownloadFieldsConfiguration;

  @Input()
  set config (config: DownloadFieldsConfiguration | null) {
    if (config != null) {
      this.configuration = config;
      this.formGroup.get('generatePartialLettering')?.setValue(config.generatePartialLettering.defaultValue);
      this.formGroup.get('accountingEntryNumberMode')?.setValue(config.accountingEntryNumberMode.defaultValue)
    }
  }

  constructor(
    private readonly fb: FormBuilder,
    readonly accountingEntryNumberModeService: AccountingEntryNumberModeService
  ) {
    this.accountingNumberModeList = accountingEntryNumberModeService.getAll();
    this.formGroup = fb.group({
      generatePartialLettering: [false, Validators.required],
      accountingEntryNumberMode: [AccountingEntryNumberModeEnum.NONE, Validators.required]
    });
  }

}
