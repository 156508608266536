import { AccountingEntriesOverrideFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

export const asciiOverrideTransformationFieldsConfiguration : AccountingEntriesOverrideFieldsConfiguration = {
    paymentProcessorConfiguration: {
        entryDescription: {
            disable: true
        }
    },

    buyInvoiceProcessorConfiguration: {
        entryDescription: {
            disable: true
        }
    },

    sellInvoiceProcessorConfiguration: {
        entryDescription: {
            disable: true
        }
    },

    bankProcessorConfiguration: {
        entryDescription: {
            disable: true
        }
    },

    analyticProcessorConfiguration: {
        disable: true
    }
}