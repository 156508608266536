import { AccountingEntryNumberModeEnum } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

export const dexDownLoadFieldsConfiguration : DownloadFieldsConfiguration = {
    toolLabel: 'dex_dl',
    generatePartialLettering: {
        defaultValue: false,
        visible: false
    },
    accountingEntryNumberMode: {
        visible: false,
        defaultValue: AccountingEntryNumberModeEnum.NONE
    }
}