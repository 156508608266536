import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { CurrentCompanyService } from '../services/current-company.service';
import { ThirdPartsType } from '~modules/thirdparts/common/entities/third-parts-type.constant';

@Directive({
    selector: '[appCurrentCompanyOfferThirdPart]'
})
export class CurrentCompanyOfferThirdPartDirective {

    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly currentCompanyService: CurrentCompanyService,
    ) {}

    @Input()
    set appCurrentCompanyOfferThirdPart(thirdPart: ThirdPartsType) {
        this.currentCompanyService
            .currentCompanyOfferCanAccess$(thirdPart)
            .pipe(
                first()
            ).subscribe(res => {
                if (res === true) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
