import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentDescriptionFieldOptions, getSellInvoiceDescriptionFieldOptions, thirdPartTemplatingNameFieldOption, thirdPartyTemplatingOptions } from './default-transformation-fields';

const uberEatsPaymentDescriptionOptions: DescriptionOption[] = getPaymentDescriptionFieldOptions({
    withThirdPart: true
});

const uberEatsSellInvoiceDescriptionOptions: DescriptionOption[] = getSellInvoiceDescriptionFieldOptions({
    withThirdPart: true
});

export const uberEatsTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Uber Eats',

    paymentProcessorConfiguration: {
        journalOption: {
            defaultValue: JournalOptionEnum.UNIQUE,
        },
        uniqueJournal: {
            code: {
                defaultValue: 'UBER'
            },
            label: {
                defaultValue: 'Uber Eats (encaissements)'
            }
        },
        client: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            visible: false,
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        methods: {
            unique: false,
            paymentMethodOptions: [
                'UBER EATS',
                'Sodexo',
                'Swile',
                'Pluxee'
            ],
            internalTransferVisibleFor: ['UBER EATS'],
            defaultMethod: {
                name: null,
                export: true,
                journalCode: null,
                internalTransfer: {
                    account: '58099',
                    label: null
                },
                provider: {
                    account: '51299',
                    label: null
                }
            },
            methods: [
                {
                    name: 'UBER EATS',
                    export: true,
                    journalCode: 'UBER',
                    internalTransfer: {
                        account: '581',
                        label: null
                    },
                    provider: {
                        account: '5171',
                        label: null
                    }
                },
            ]
        },
        providerFee: {
            account: {
                defaultValue: '6222'
            },
            label: {
                defaultValue: 'Commissions et courtages sur ventes'
            }
        },
        deductibleVat20: {
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        description: {
            options: uberEatsPaymentDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ transaction.type }} • {{ transaction.subTypeId | default(transaction.transactionId) }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VTU'
            },
            label: {
                defaultValue: 'Ventes Uber Eats'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'UBEREATS'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                visible: false,
                defaultValue: false
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_VAT_RATE
            },
            oneLinePerVatRate: {
                productVats: {
                    columns: {
                        countryCode: {
                            canEdit: false,
                            defaultValue: 'FR'
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: 'FR',
                            regime: null,
                            rate: 0,
                            account: '701000',
                            accountLabel: 'Ventes TVA 0%'
                        },
                        {
                            countryCode: 'FR',
                            regime: null,
                            rate: 5.5,
                            account: '701055',
                            accountLabel: 'Ventes TVA 5,5%'
                        },
                        {
                            countryCode: 'FR',
                            regime: null,
                            rate: 10,
                            account: '701010',
                            accountLabel: 'Ventes TVA 10%'
                        },
                        {
                            countryCode: 'FR',
                            regime: null,
                            rate: 20,
                            account: '701020',
                            accountLabel: 'Ventes TVA 20%'
                        }
                    ]
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            visible: false,
            defaultValue: false
        },
        collectedVats: {
            columns: {
                countryCode: {
                    canEdit: false,
                    defaultValue: 'FR'
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: [
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 5.5,
                    account: '44571055',
                    accountLabel: 'TVA collectée 5,5%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 10,
                    account: '44571010',
                    accountLabel: 'TVA collectée 10%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 20,
                    account: '44571020',
                    accountLabel: 'TVA collectée 20%'
                }
            ]
        },
        amountDifferenceOption: {
            visible: false,
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            visible: false,
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            visible: false,
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        description: {
            options: uberEatsSellInvoiceDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ invoice.type }} • {{ invoice.invoiceFunctionalId | default(invoice.invoiceId) }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}