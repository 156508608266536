import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Observable, combineLatest, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { CompanyDetails } from '../entities/company-details.entity';
import { CompanyDomain } from '../entities/company-domain.entity';
import { CompanyRight } from '../entities/company-right.entity';
import { QuotaObject } from '~modules/offer/entities/company-offer-details';
import { OfferService } from '~modules/offer/services/offer.service';
import { CompanyQuotaCountAndLimit } from '../entities/company-quota-count-and-limit.entity';
import { AuthenticationService } from '~modules/auth/services/authentication.service';
import { ExtractNodeEnum, LoadNodeEnum } from '~modules/connectors/dtos/create-update-global-connector.request';
import { ThirdPartsType } from '~modules/thirdparts/common/entities/third-parts-type.constant';

@Injectable()
export class CurrentCompanyService implements OnDestroy {

    private authenticationSubscription: Subscription;
    private includedThirdPartsSubscription : Subscription;

    private _current$ = new ReplaySubject<CompanyDetails>(1);
    private _currentRights$ = new ReplaySubject<Record<CompanyDomain,CompanyRight>>(1);
    private _currentQuotas$ = new ReplaySubject<Record<QuotaObject,number>>(1);
    private _currentConnectorsIncludedExtractNodes$ = new ReplaySubject<ExtractNodeEnum[]>(1);
    private _currentConnectorsIncludedLoadNodes$ = new ReplaySubject<LoadNodeEnum[]>(1);
    private _currentConnectorsIncludedThirdParts$ = new ReplaySubject<ThirdPartsType[]>(1);
    private _currentTransferToolsIncludedThirdParts$ = new ReplaySubject<ThirdPartsType[]>(1);
    private _currentIncludedThirdParts$ = new ReplaySubject<ThirdPartsType[]>(1);

    constructor(
        private readonly companyService: CompanyService,
        private readonly offerService: OfferService,
        private readonly authService: AuthenticationService
    ) {
        this.authenticationSubscription = this.authService
            .currentUser$
            .subscribe((user) => {
                if (user == null) {
                    this.unselect();
                } else {
                    this.select();
                }
            });
        this.includedThirdPartsSubscription = combineLatest([
            this._currentConnectorsIncludedThirdParts$,
            this._currentTransferToolsIncludedThirdParts$
          ]).subscribe({
            next: ([connectors, transferTools]) => {
                this._currentIncludedThirdParts$.next(
                    [...new Set([...connectors, ...transferTools])] // merge unique values
                )
            }
        });
    }

    ngOnDestroy(): void {
        this.authenticationSubscription?.unsubscribe();
        this.includedThirdPartsSubscription?.unsubscribe();
    }

    /** MANAGEMENT OF OBSERVABLE */

    unselect() {
        this._current$ = new ReplaySubject(1);
        this._currentRights$ = new ReplaySubject(1);
        this._currentQuotas$ = new ReplaySubject(1);
    }

    select() {
        this
            .companyService
            .getCurrentUserCompanyDetails$()
            .pipe(
                first()
            )
            .subscribe(res => {
                this.next(res);
            });
        this
            .companyService
            .getCurrentUserCompanyRights$()
            .pipe(
                first()
            )
            .subscribe(res => {
                this.nextRights(res);
            });
        this
            .offerService
            .getCurrentUserCompanyOfferQuotasLimit$()
            .pipe(
                first()
            )
            .subscribe(res => {
                this.nextQuotas(res);
            });
        this
            .offerService
            .getCurrentUserCompanyConnectorsOptionIncluded$()
            .pipe(
                first()
            )
            .subscribe(res => {
                this.nextCurrentConnectorsIncludedExtractNodes(res.extractNodes);
                this.nextCurrentConnectorsIncludedLoadNodes(res.loadNodes);
                this.nextCurrentConnectorsIncludedThirdParts(res.thirdParts);
            });
    }

    /** COMPANY SIMPLE DETAILS */

    get current$(): Observable<CompanyDetails> {
        return this._current$.asObservable();
    }

    next(company: CompanyDetails) {
        this._current$.next(company);
    }

    /** COMPANY RIGHTS */

    get currentRights$(): Observable<Record<CompanyDomain,CompanyRight>> {
        return this._currentRights$.asObservable();
    }

    nextRights(rights: Record<CompanyDomain, CompanyRight>) {
        this._currentRights$.next(rights);
        // TODO when developing transfer tools, move this temporary hack to a real endpoint
        if ('TRANSFER_TOOL' in rights && rights['TRANSFER_TOOL'] != null && rights['TRANSFER_TOOL'] != 'NONE') {
            this._currentTransferToolsIncludedThirdParts$.next(['INQOM']);
        } else {
            this._currentTransferToolsIncludedThirdParts$.next([]);
        }
    }

    currentUserCanAccess$(domain: CompanyDomain, right: CompanyRight): Observable<boolean> {
        return this.currentRights$.pipe(
            map((currentUserRights: Record<CompanyDomain,CompanyRight>) => {
                const currentUserRight = currentUserRights[domain];
                if (right === 'NONE') {
                    return currentUserRight === 'NONE' || currentUserRight === 'READ' || currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                } else if (right === 'READ') {
                    return currentUserRight === 'READ' || currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                } else if (right === 'EXECUTE') {
                    return currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                } else if (right === 'WRITE') {
                    return currentUserRight === 'WRITE';
                } else {
                    return false;
                }
            })
        );
    }

    currentUserCanAccessOneOf$(neededRights: {domain: CompanyDomain, right: CompanyRight}[]): Observable<boolean> {
        return this.currentRights$.pipe(
            map((currentUserRights: Record<CompanyDomain,CompanyRight>) => {
                return neededRights.filter(({domain, right}) => {
                    const currentUserRight = currentUserRights[domain];
                    if (right === 'NONE') {
                        return currentUserRight === 'NONE' || currentUserRight === 'READ' || currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                    } else if (right === 'READ') {
                        return currentUserRight === 'READ' || currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                    } else if (right === 'EXECUTE') {
                        return currentUserRight === 'EXECUTE' || currentUserRight === 'WRITE';
                    } else if (right === 'WRITE') {
                        return currentUserRight === 'WRITE';
                    } else {
                        return false;
                    }
                }).length > 0;
            })
        );
    }

    /** COMPANY QUOTAS */

    get currentQuotasLimit$(): Observable<Record<QuotaObject,number>> {
        return this._currentQuotas$.asObservable();
    }

    nextQuotas(quotas: Record<QuotaObject,number>) {
        this._currentQuotas$.next(quotas);
    }

    currentQuotasObjectLimit$(quotaObject: QuotaObject): Observable<number> {
        return this
            ._currentQuotas$
            .pipe(
                map((quotas => quotas[quotaObject] || 0))
            );
    }

    currentQuotasObjectCount$(quotaObject: QuotaObject): Observable<number> {
        return this.offerService.getCurrentUserCompanyCountForQuotaObject$(quotaObject);
    }

    currentQuotasObjectCountAndLimit$(quotaObject: QuotaObject): Observable<CompanyQuotaCountAndLimit> {
        return combineLatest([
            this.currentQuotasObjectCount$(quotaObject),
            this.currentQuotasObjectLimit$(quotaObject)
        ]).pipe(
            map(([count, limit]) => {
                return {
                    count,
                    limit
                }
            })
        );
    }

    isQuotaObjectReached$(quotaObject: QuotaObject): Observable<boolean> {
        return this.currentQuotasObjectCountAndLimit$(quotaObject)
            .pipe(
                map(({count, limit}) => {
                    return count >= limit;
                })
            );
    }

    /** COMPANY CONNECTORS OPTION */

    get currentConnectorsIncludedExtractNodes$(): Observable<ExtractNodeEnum[]> {
        return this._currentConnectorsIncludedExtractNodes$.asObservable();
    }

    nextCurrentConnectorsIncludedExtractNodes(nodes: ExtractNodeEnum[]) {
        this._currentConnectorsIncludedExtractNodes$.next(nodes);
    }

    get currentConnectorsIncludedLoadNodes$(): Observable<LoadNodeEnum[]> {
        return this._currentConnectorsIncludedLoadNodes$.asObservable();
    }

    nextCurrentConnectorsIncludedLoadNodes(nodes: LoadNodeEnum[]) {
        this._currentConnectorsIncludedLoadNodes$.next(nodes);
    }

    get currentConnectorsIncludedThirdParts$(): Observable<ThirdPartsType[]> {
        return this._currentConnectorsIncludedThirdParts$.asObservable();
    }

    nextCurrentConnectorsIncludedThirdParts(thirdParts: ThirdPartsType[]) {
        this._currentConnectorsIncludedThirdParts$.next(thirdParts);
    }

    /** COMPANY OFFER */
    
    currentCompanyOfferCanAccess$(thirdPart: ThirdPartsType): Observable<boolean> {
        return this
            ._currentIncludedThirdParts$
            .pipe(
                map(includedThirdParts => includedThirdParts.includes(thirdPart))
            );
    }
}
