<div class="content">

    <mat-card id="inner-content">
        
        <div class="logo">
            <img src="/assets/img/logo_square.svg" alt="logo"/>
        </div>

        <div>
            <router-outlet></router-outlet>
            <span class="logo-text-app-version">v{{currentApplicationVersion}}&nbsp;-&nbsp;<span class="release-stage">beta</span></span>
        </div>
            
    </mat-card>
</div>
