import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { defaultDynamicConfigMaxLength, DescriptionFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { DescriptionProcessorConfiguration } from '../../entities/description-processor-configuration.entity';

type DescriptionFormGroup = {
  descriptionOption: FormControl<DescriptionOption | null>;
  hasTemplate: FormControl<boolean | null>;
  template: FormControl<string | null>;
}

@Component({
  selector: 'app-description-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TextFieldModule
  ],
  templateUrl: './description-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./description-configuration.component.scss']
})
export class DescriptionConfigurationComponent implements OnDestroy {

  formGroup!: FormGroup<DescriptionFormGroup>;

  descriptionOptionSubscription?: Subscription;

  fieldsConfig: DescriptionFieldsConfiguration | undefined;
  descriptionConfig: DescriptionProcessorConfiguration | undefined;

  @Input({ required: true })
  descriptionOptionLabel!: string;

  @Input({ required: true })
  descriptionTemplateLabel!: string;

  @Input({ required: true })
  set descriptionConfiguration (value: DescriptionProcessorConfiguration | undefined) {
    if (value) {
      const descriptionOption = this.formGroup.get('descriptionOption');
      const hasTemplate = this.formGroup.get('hasTemplate');
      const template = this.formGroup.get('template');

      this.descriptionConfig = value;
      // search correct option and then in case of dynamic label we can override default option value with the one from config
      descriptionOption?.setValue(this.findDescriptionOptionByDescriptionProperties(value.template, value.hasTemplate));
      hasTemplate?.patchValue(value.hasTemplate);
      template?.patchValue(value.template);
      this.formGroup?.markAllAsTouched();
    }
  }

  @Input({ required: true })
  set fieldsConfiguration (value: DescriptionFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;
      
      const descriptionOption = this.formGroup.get('descriptionOption');
      const hasTemplate = this.formGroup.get('hasTemplate');
      const template = this.formGroup.get('template');
      const templateMaxLength = value?.templateMaxLength || defaultDynamicConfigMaxLength;

      const defaultOption: DescriptionOption = value.defaultOption;
      descriptionOption?.setValue(defaultOption);
      hasTemplate?.setValue(defaultOption.hasTemplate);
      if (defaultOption.hasTemplate === true) {
        template?.setValidators([Validators.required, Validators.maxLength(templateMaxLength)]);
        template?.setValue(defaultOption.templateValue || null);
      } else {
        template?.setValidators([Validators.maxLength(templateMaxLength)]);
        template?.setValue(null);
      }
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      descriptionOption: new FormControl<DescriptionOption | null>(null, [Validators.required]),
      hasTemplate: new FormControl<boolean | null>(false, [Validators.required]),
      template: new FormControl<string | null>(null, [Validators.required]),
    });
    const descriptionOption = this.formGroup.get('descriptionOption');
    const hasTemplate = this.formGroup.get('hasTemplate');
    const template = this.formGroup.get('template');

    this.descriptionOptionSubscription = descriptionOption?.valueChanges.subscribe({
      next: (value) => {
        if (value != null) {
          const templateMaxLength = this.fieldsConfig?.templateMaxLength != null ? this.fieldsConfig?.templateMaxLength : defaultDynamicConfigMaxLength;

          hasTemplate?.setValue(value.hasTemplate);
          if (value.hasTemplate === true) {
            template?.setValidators([Validators.required, Validators.maxLength(templateMaxLength)]);
            template?.setValue(value.templateValue || null);
          } else {
            template?.setValidators([Validators.maxLength(templateMaxLength)]);
            template?.setValue(null);
          }
          template?.updateValueAndValidity();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.descriptionOptionSubscription?.unsubscribe();
  }

  avoidLineBreak(event: KeyboardEvent) {
    if(event.key == 'Enter' || (event.shiftKey && event.key == 'Enter')) {
      event.preventDefault();
    }
  }

  findDescriptionOptionByDescriptionProperties(template: string, hasTemplate: boolean): DescriptionOption | null {
    const result = this.fieldsConfig?.options.find((option => option.hasTemplate === hasTemplate && ((option.templateValue == null && template == null) || option.templateValue === template)));
    return result || this.fieldsConfig?.options[this.fieldsConfig?.options.length - 1] || null;
  }

  compareSelectionObjects(object1: DescriptionOption, object2: DescriptionOption) {
    return object1 && object2 && object1.label === object2.label && object1.hasTemplate === object2.hasTemplate;
  }
}