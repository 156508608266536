<form [formGroup]="formGroup">

  <h4><mat-icon>login</mat-icon>&nbsp;Connexion</h4>

  <mat-form-field class="full-width">
      <mat-label>Choix de la connexion</mat-label>
      <mat-select formControlName="authenticationId" required>
          <mat-option 
              *ngFor="let connection of connectionsOptions$ | async"
              [value]="connection.id">
              {{connection.displayName}}
          </mat-option>
      </mat-select>
  </mat-form-field>

  <h4>
    <mat-icon>settings</mat-icon>&nbsp;Type d'extraction&nbsp;
    <mat-icon 
        [matTooltipPosition]="'after'"
        matTooltip="Choix du critère utilisé pour récupérer les écritures depuis Axonaut : la date du document ou sa date de création. Klafuti préconise d'utiliser la date de création pour s'assurer de récupérer les documents qui seraient créés dans le “passé”.">
      help_outlined
    </mat-icon></h4>

  <mat-form-field class="full-width">
    <mat-label>Option de l'extraction</mat-label>
    <mat-select formControlName="dateFilter" [compareWith]="compareFunction">
      <mat-option *ngFor="let option of axonautFilterDateOptions" [value]="option.value">{{option.label}}</mat-option>
    </mat-select>
  </mat-form-field>
</form>