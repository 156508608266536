<form [formGroup]="formGroup">

  <h4><mat-icon>login</mat-icon>&nbsp;Connexion</h4>
  <mat-form-field class="full-width">
      <mat-label>Choix de la connexion</mat-label>
      <mat-select formControlName="authenticationId" required>
          <mat-option 
              *ngFor="let connection of connectionsOptions$ | async"
              [value]="connection.id">
              {{connection.displayName}}
          </mat-option>
      </mat-select>
  </mat-form-field>

  <h4><mat-icon>folder</mat-icon>&nbsp;Dossier</h4>
  <mat-form-field class="two-thirds-width">
    <mat-label>Numéro de dossier</mat-label>
    <input matInput formControlName="enterpriseId">
  </mat-form-field>

  <h4><mat-icon>error</mat-icon>&nbsp;Compte d'erreur</h4>
  <div class="row danger-info">
    <small>Si un compte n'est pas impactable dans Inqom, il sera remplacé par le compte suivant.</small>
  </div>
  <mat-form-field class="thirds-width">
    <mat-label>Compte d'erreur</mat-label>
    <input matInput formControlName="failureAccount">
  </mat-form-field>
</form>