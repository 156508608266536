import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { ThirdPartsInqomAuthenticationService } from '../../services/third-parts-inqom-authentication.service';
import { MatInputModule } from '@angular/material/input';
import { defaultMainAccountMaxLength, DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';


@Component({
  selector: 'app-load-inqom-journals-api-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule
  ],
  templateUrl: './load-inqom-journals-api-configuration.component.html',
  styleUrls: ['./load-inqom-journals-api-configuration.component.scss']
})
export class LoadInqomJournalsApiConfigurationComponent {
  formGroup: FormGroup;
  configuration?: DownloadFieldsConfiguration;

  connectionsOptions$?: Observable<ThirdPartsAuthenticationList[]>;

  @Input()
  set config (config: DownloadFieldsConfiguration | null) {
    if (config != null) {
      this.configuration = config;
    }
  }

  constructor(
    readonly fb: FormBuilder,
    protected readonly authenticationService: ThirdPartsInqomAuthenticationService,

  ) {
    this.formGroup = fb.group({
      authenticationId: [null, Validators.required],
      enterpriseId: [null, Validators.required],
      failureAccount: ['471', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]]
    });

    this.connectionsOptions$ = this.authenticationService.list$();
  }
}