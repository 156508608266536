import { BreakpointObserver, Breakpoints, BreakpointState, LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/modules/auth/entities/user.entity';
import { AuthenticationService } from 'src/app/modules/auth/services/authentication.service';
import { environment } from 'src/environments/environment';
import { CompanyModule } from '~modules/company/company.module';
import { ConnectorsModule } from '~modules/connectors/connectors.module';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    LayoutModule,
    RouterModule,
    CommonModule,
    ConnectorsModule,
    CompanyModule
  ]
})
export class LoggedLayoutComponent implements OnInit, OnDestroy {

  user$!: Observable<User|null>;
  currentApplicationVersion: string = environment.appVersion;
  destroyed = new Subject<void>();

  currentScreenSize!: BreakpointState;

  @ViewChild(MatDrawer)
  drawer!: MatDrawer;

  constructor(
    private authService: AuthenticationService,
    private breakpoint: BreakpointObserver,
  ) {}

  manageSideNavOpeningWithScreenSize(breakpoint: BreakpointObserver): void {
    breakpoint
    .observe([
      Breakpoints.XSmall,
    ])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      if (result.matches === true) {
        this.drawer?.close();
      }
    });

    breakpoint
    .observe([
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      if (result.matches === true) {
        this.drawer?.open();
      }
    });
  }

  ngOnInit(): void {
    this.authService.updateUserConnections$().subscribe();
    this.manageSideNavOpeningWithScreenSize(this.breakpoint);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  signOut(): void {
    this.authService.signOut();
  }
}
