import { Injectable } from "@angular/core";
import { LoadConnectorType } from "../models/load-connector.type";
import { LoadNodeEnum } from '~modules/connectors/dtos/create-update-global-connector.request';
import { LoadDexDownloadConfigurationComponent } from "../modules/load/dex-download/components/load-dex-download-configuration/load-dex-download-configuration.component";
import { LoadAsciiDownloadConfigurationComponent } from "../modules/load/ascii-download/components/load-ascii-download-configuration/load-ascii-download-configuration.component";
import { LoadInqomFecDownloadConfigurationComponent } from "../modules/load/inqom-fec-download/components/load-inqom-fec-download-configuration/load-inqom-fec-download-configuration.component";
import { LoadFecDownloadConfigurationComponent } from "../modules/load/fec-download/components/load-fec-download-configuration/load-fec-download-configuration.component";
import { LoadEcrDownloadConfigurationComponent } from "../modules/load/ecr-download/components/load-ecr-download-configuration/load-ecr-download-configuration.component";
import { LoadInqomZipFecDownloadConfigurationComponent } from "../modules/load/inqom-zip-fec-download/components/load-inqom-zip-fec-download-configuration/load-inqom-zip-fec-download-configuration.component";
import { Observable, take, switchMap, of } from 'rxjs';
import { CurrentCompanyService } from "~modules/company/services/current-company.service";
import { LoadZipAsciiDownloadConfigurationComponent } from "../modules/load/zip-ascii-download/components/load-ascii-download-configuration/load-zip-ascii-download-configuration.component";
import { ecrDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/ecr-download-fields-configuration';
import { dexDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/dex-download-fields-configuration';
import { asciiDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/ascii-download-fields-configuration';
import { inqomFecDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/inqom-fec-download-fields-configuration';
import { inqomZipFecDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/inqom-zip-fec-download-fields-configuration';
import { fecDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/fec-download-fields-configuration';
import { zipAsciiDownLoadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/load-fields/zip-ascii-download-fields-configuration';
import { LoadInqomJournalsApiConfigurationComponent } from "~modules/thirdparts/inqom/components/load-inqom-journals-api-configuration/load-inqom-journals-api-configuration.component";
import { inqomJournalsApiFieldsConfiguration } from "./extract-connectors-configurations/load-fields/inqom-journals-api-fields-configuration";
import { LoadMyUnisoftZipFecDownloadConfigurationComponent } from "../modules/load/myunisoft-zip-fec-download/components/load-myunisoft-zip-fec-download-configuration/load-myunisoft-zip-fec-download-configuration.component";
import { myUnisoftZipFecDownLoadFieldsConfiguration } from "./extract-connectors-configurations/load-fields/myunisoft-zip-fec-download-fields-configuration";
import { fecOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/fec-override-transformation-fields-configuration";
import { myUnisoftZipFecOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/myunisoft-zip-fec-override-transformation-fields-configuration";
import { inqomZipFecOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/inqom-zip-fec-override-transformation-fields-configuration";
import { inqomFecOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/inqom-fec-override-transformation-fields-configuration";
import { inqomJournalsApiOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/inqom-journals-api-override-transformation-fields-configuration";
import { zipAsciiOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/zip-ascii-override-transformation-fields-configuration";
import { asciiOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/ascii-override-transformation-fields-configuration";
import { ecrOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/ecr-override-transformation-fields-configuration";
import { dexOverrideTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/override/dex-override-transformation-fields-configuration";

export const loadConnectorsNodeList: LoadConnectorType[] = [
    {
        configurationComponent: LoadDexDownloadConfigurationComponent,
        id: 'dex_dl',
        iconUrl: '/assets/img/connectors/load-nodes/agiris-dex-pj_300x300.png',
        label: 'DEX (avec pièces)',
        softwares: ['Agiris', 'Isacompta', 'Isagri'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: dexDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: dexOverrideTransformationFieldsConfiguration
        },
        type: 'DEX_DOWNLOAD'
    },
    {
        configurationComponent: LoadEcrDownloadConfigurationComponent,
        id: 'ecr_dl',
        iconUrl: '/assets/img/connectors/load-nodes/agiris-ecr_300x300.png',
        label: 'ECR',
        softwares: ['Agiris', 'Isacompta', 'Isagri'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: ecrDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: ecrOverrideTransformationFieldsConfiguration
        },
        type: 'ECR_DOWNLOAD'
    },
    {
        configurationComponent: LoadAsciiDownloadConfigurationComponent,
        id: 'ascii_dl',
        iconUrl: '/assets/img/connectors/load-nodes/quadratus_300x300.png',
        label: 'ASCII seul',
        softwares: ['Quadratus'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: asciiDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: asciiOverrideTransformationFieldsConfiguration
        },
        type: 'ASCII_DOWNLOAD'
    },
    {
        configurationComponent: LoadZipAsciiDownloadConfigurationComponent,
        id: 'zip_ascii_dl',
        iconUrl: '/assets/img/connectors/load-nodes/quadratus-zip_300x300.png',
        label: 'ASCII complet (avec pièces)',
        softwares: ['Quadratus'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: zipAsciiDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: zipAsciiOverrideTransformationFieldsConfiguration
        },
        type: 'ZIP_ASCII_DOWNLOAD'
    },
    {
        configurationComponent: LoadInqomJournalsApiConfigurationComponent,
        id: 'inqom_journals_api',
        iconUrl: '/assets/img/connectors/load-nodes/inqom-api_300x300.png',
        label: 'Inqom API',
        softwares: ['Inqom'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: inqomJournalsApiFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: inqomJournalsApiOverrideTransformationFieldsConfiguration
        },
        type: 'INQOM_API'
    },
    {
        configurationComponent: LoadInqomFecDownloadConfigurationComponent,
        id: 'inqom_fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/inqom-fec_300x300.png',
        label: 'Inqom FEC avec analytique',
        softwares: ['Inqom'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: inqomFecDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: inqomFecOverrideTransformationFieldsConfiguration
        },
        type: 'INQOM_FEC_DOWNLOAD'
    },
    {
        configurationComponent: LoadInqomZipFecDownloadConfigurationComponent,
        id: 'inqom_zip_fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/inqom-zip_300x300.png',
        label: 'Inqom ZIP (FEC, pièces et analytique)',
        softwares: ['Inqom'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: inqomZipFecDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: inqomZipFecOverrideTransformationFieldsConfiguration
        },
        type: 'INQOM_ZIP_FEC_DOWNLOAD'
    },
    {
        configurationComponent: LoadMyUnisoftZipFecDownloadConfigurationComponent,
        id: 'myunisoft_zip_fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/MyUnisoft-zip_300x300.png',
        label: 'MyUnisoft ZIP (FEC, pièces)',
        softwares: ['MyUnisoft'],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: myUnisoftZipFecDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: myUnisoftZipFecOverrideTransformationFieldsConfiguration
        },
        type: 'MYUNISOFT_ZIP_FEC_DOWNLOAD'
    },
    {
        configurationComponent: LoadFecDownloadConfigurationComponent,
        id: 'fec_dl',
        iconUrl: '/assets/img/connectors/load-nodes/file-download_300x300.png',
        label: 'FEC',
        softwares: [],
        inputTypes: [
            'ACCOUNTING_ENTRIES'
        ],
        loadFieldsConfiguration: fecDownLoadFieldsConfiguration,
        overrideTransformFieldsConfiguration: {
            accountingEntries: fecOverrideTransformationFieldsConfiguration
        },
        type: 'FEC_DOWNLOAD'
    }
];

@Injectable()
export class LoadConnectorsService {

    constructor(
        private readonly currentCompanyService: CurrentCompanyService
    ) {}

    getOneByType(type: LoadNodeEnum): LoadConnectorType | null {
        const connector = this.getList().find(element => element.type === type);
        if (connector == null) {
            return null;
        }
        return connector;
    }

    getAvailableOnes$(): Observable<LoadConnectorType[]> {
        return this.currentCompanyService.currentConnectorsIncludedLoadNodes$.pipe(
            take(1),
            switchMap(
                nodeTypes => of(this.getList().filter((tool) => nodeTypes.includes(tool.type)).sort((a, b) => a.label.localeCompare(b.label)))
            )
        );
    }

    getList(): LoadConnectorType[] {
        return loadConnectorsNodeList;
    }
}
