import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { defaultEntryDescriptionEmptyFieldOption, defaultEntryLineDescriptionStandardFieldOption, getSellInvoiceEntryDescriptionFieldOptions, getSellInvoiceEntryLineDescriptionFieldOptions, supplierThirdPartTemplatingNameFieldOption, supplierThirdPartyTemplatingOptions } from './default-transformation-fields';
import { DocumentRefOptionEnum, getDocumentRefOptions } from '~modules/connectors/constants/document-ref.option';

const libeoBuyInvoiceEntryDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryDescriptionFieldOptions({
    withThirdPart: true
});

const libeoBuyInvoiceEntryLineDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

export const libeoTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Libeo',

    buyInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'ACH'
            },
            label: {
                defaultValue: 'Achats'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: ''
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'Fournisseurs'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'FOURNISSEURS'
            },
            auxiliaryDynamic: {
                options: supplierThirdPartyTemplatingOptions,
                defaultOption: supplierThirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_PRODUCT_ACCOUNT
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    canAdd: false,
                    columns: {
                        countryCode: {
                            visible: false,
                            canEdit: false
                        },
                        regime: {
                            visible: false,
                            canEdit: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '471',
                        accountLabel: 'Charges à reclasser'
                    }
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '471'
                    },
                    label: {
                        defaultValue: 'Charges à reclasser'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            defaultValue: true
        },
        collectedVats: {
            canAdd: false,
            columns: {
                countryCode: {
                    visible: false,
                    canEdit: false
                },
                regime: {
                    visible: false,
                    canEdit: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44566099',
                accountLabel: 'TVA à reclasser'
            },
            accounts: []
        },
        amountDifferenceOption: {
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        documentRef: {
            visible: false,
            options: getDocumentRefOptions(),
            defaultValue: DocumentRefOptionEnum.INVOICE_FUNCTIONAL_ID
        },
        entryDescription: {
            options: libeoBuyInvoiceEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption
        },
        entryLineDescription: {
            options: libeoBuyInvoiceEntryLineDescriptionOptions,
            defaultOption: defaultEntryLineDescriptionStandardFieldOption
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}