<mat-toolbar color="primary"  role="heading">
  <button id="nav_menu" mat-icon-button (click)="drawer.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <a id="logo" [routerLink]="['']" class="link">
    <img src="/assets/img/logo_top_bar.png" alt="logo"/>
  </a>
  <span class="spacer"></span>
  <span id="app-version">v{{currentApplicationVersion}}<span id="release-stage">&nbsp;beta</span></span>
  <button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu" yPosition="below">
    <a mat-menu-item [routerLink]="['company']" *appCurrentCompanyRight="{domain: 'COMPANY', right: 'READ'}">
      <mat-icon>corporate_fare</mat-icon>
      <span>Mon organisation</span></a>
    <a mat-menu-item [routerLink]="['/auth/update-password']">
      <mat-icon>password</mat-icon>
      <span>Mettre à jour mon mot de passe</span></a>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>logout</mat-icon>
      <span>Me déconnecter</span></button>
  </mat-menu>
</mat-toolbar>

<mat-drawer-container id="sidenav">

  
  <mat-drawer #drawer mode="side" opened>
    <div class="side-menu">
      <ng-container *appCurrentCompanyRight="{domain: 'FOLDER', right: 'READ'}">
        <span class="side-menu_category">Mon organisation</span>
        <a
          [routerLink]="['folders']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'FOLDER', right: 'READ'}"
          >
                <mat-icon color="light">list</mat-icon>
          <span class="side-menu_label">
            Dossiers comptables
          </span>
        </a>
        <span class="side-menu_divider"></span>
      </ng-container>


      <ng-container *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}, {domain: 'TRANSFER_TOOL', right: 'READ'}]">
        <span class="side-menu_category">Connexions</span>
        <ng-container *appCurrentCompanyOfferThirdPart="'INQOM'">
          <a
            [routerLink]="['third-parts/inqom']"
            [routerLinkActive]="['active-link']"
            [routerLinkActiveOptions]="{exact: true}"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}, {domain: 'TRANSFER_TOOL', right: 'READ'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Inqom
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'STRIPE'">
          <a
            [routerLink]="['third-parts/stripe']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
          >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Stripe
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'PAYPAL'">
          <a
            [routerLink]="['third-parts/paypal']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Paypal
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'GOCARDLESS'">
          <a
            [routerLink]="['third-parts/gocardless']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              GoCardless
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'ALMA'">
          <a
            [routerLink]="['third-parts/alma']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Alma
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'QONTO'">
          <a
            [routerLink]="['third-parts/qonto']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Qonto
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'AXONAUT'">
          <a
            [routerLink]="['third-parts/axonaut']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Axonaut
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'CASH_AND_REPAIR'">
          <a
            [routerLink]="['third-parts/cash-and-repair']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Cash & Repair
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'NEXT_MOTION'">
          <a
            [routerLink]="['third-parts/next-motion']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Next Motion
            </span>
          </a>
        </ng-container>
        <ng-container *appCurrentCompanyOfferThirdPart="'LIBEO'">
          <a
            [routerLink]="['third-parts/libeo']"
            [routerLinkActive]="['active-link']"
            class="side-menu_item"
            *appCurrentCompanyRights="[{domain: 'CONNECTOR', right: 'READ'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]"
            >
            <mat-icon color="light">settings</mat-icon>
            <span class="side-menu_label">
              Libeo
            </span>
          </a>
        </ng-container>
        <span class="side-menu_divider"></span>
      </ng-container>


      <ng-container *appCurrentCompanyRights="[{domain: 'STANDALONE_TOOL', right: 'EXECUTE'}, {domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}]">
        <span class="side-menu_category">Outils</span>
        <a
          [routerLink]="['tools/fec-verifier']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">checklist</mat-icon>
          <span class="side-menu_label">
            Vérificateur de FEC
          </span>
        </a>
        <a
          [routerLink]="['tools/extract-accounting-assets']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">download</mat-icon>
          <span class="side-menu_label">
            Extraction d'immobilisations
          </span>
        </a>
        <a
          [routerLink]="['tools/extract-tra-accounting-folder']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">download</mat-icon>
          <span class="side-menu_label">
            Extraction .TRA CEGID
          </span>
        </a>
        <a
          [routerLink]="['tools/extract-ascii-fec']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">download</mat-icon>
          <span class="side-menu_label">
            Conversion .ASCII vers FEC
          </span>
        </a>
        <a
          [routerLink]="['tools/extract-accounting-entries/api']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">download</mat-icon>
          <span class="side-menu_label">
            Extraction d'écritures (API)
          </span>
        </a>
        <a
          [routerLink]="['tools/extract-accounting-entries/file']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'STANDALONE_CONNECTOR_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">download</mat-icon>
          <span class="side-menu_label">
            Extraction d'écritures (FICHIER)
          </span>
        </a>
        <span class="side-menu_divider"></span>
      </ng-container>
      

      <ng-container *appCurrentCompanyRight="{domain: 'TRANSFER_TOOL', right: 'READ'}">
        <span class="side-menu_category">Migration</span>
        <a
          [routerLink]="['migration/project']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'TRANSFER_TOOL', right: 'READ'}"
          >
          <mat-icon color="light">settings</mat-icon>
          <span class="side-menu_label">
            Projets de migration
          </span>
        </a>
        <a
          [routerLink]="['migration/folder']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'TRANSFER_TOOL', right: 'READ'}"
          >
          <mat-icon color="light">list</mat-icon>
          <span class="side-menu_label">
            Dossiers de migration
          </span>
        </a>
        <a
          [routerLink]="['migration/upload']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          *appCurrentCompanyRight="{domain: 'TRANSFER_TOOL', right: 'EXECUTE'}"
          >
          <mat-icon color="light">upload_file</mat-icon>
          <span class="side-menu_label">
            Uploads
          </span>
        </a>
        <span class="side-menu_divider"></span>
      </ng-container>

      <ng-container>
        <span class="side-menu_category">Support</span>
        <a
          [routerLink]="['support']"
          [routerLinkActive]="['active-link']"
          class="side-menu_item"
          >
          <mat-icon color="light">contact_support</mat-icon>
          <span class="side-menu_label">
            Contacter le support
          </span>
        </a>
        <span class="side-menu_divider"></span>
      </ng-container>

    </div>
  </mat-drawer>

  <mat-drawer-content class="layout-content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #loading>
  <div class="loading-wrapper">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
  </div>
</ng-template>
