import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { defaultAccountLabelMaxLength, defaultMainAccountMaxLength, defaultAuxiliaryAccountMaxLength, defaultDynamicConfigMaxLength, ThirdPartyAccountSectionFieldsConfiguration, ThirdPartyTemplateOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { ThirdPartyProcessorConfiguration } from '../../entities/third-party-processor-configuration.entity';
import { MatSelectModule } from '@angular/material/select';
import { thirdPartTemplatingAdvancedFieldOption } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/default-transformation-fields';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-third-party-processor-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  templateUrl: './third-party-processor-configuration.component.html',
  styleUrls: ['./third-party-processor-configuration.component.scss']
})
export class ThirdPartyProcessorConfigurationComponent implements OnDestroy {

  formGroup!: FormGroup;
  thirdPartyTemplatingOptions: ThirdPartyTemplateOption[] = [];

  @Input()
  toolLabel!: string | undefined;
  thirdPartyTypeLabel = 'client';

  hasDynamicSubscription?: Subscription;
  thirdPartyTemplatingOptionSubscription?: Subscription;
  fieldsConfig: ThirdPartyAccountSectionFieldsConfiguration | undefined;

  @Input()
  set thirdPartyType(value: 'CLIENT'|'SUPPLIER') {
    if (value === 'CLIENT') {
      this.thirdPartyTypeLabel = 'client';
    } else if (value === 'SUPPLIER') {
      this.thirdPartyTypeLabel = 'fournisseur';
    }
  }

  @Input()
  set thirdPartyAccount(value: ThirdPartyProcessorConfiguration | undefined) {
    if (this.thirdPartyTemplatingOptions.length <= 0) {
      console.error('cannot init value (thirdPartyTemplatingOptions is empty)');
    } else if (value) {
      this.formGroup.patchValue(value, { emitEvent: false }); // do not call hasDynamicSubscription

      if (value.hasDynamic !== null && value.hasDynamic) {
        let thirdPartyTemplatingOption = this.thirdPartyTemplatingOptions.find((option) => option.auxiliaryAccountDynamic === value.auxiliaryAccountDynamic && option.auxiliaryLabelDynamic === value.auxiliaryAccountLabelDynamic) || null;
        if (thirdPartyTemplatingOption == null) {
          thirdPartyTemplatingOption = thirdPartTemplatingAdvancedFieldOption;
        }
        this.formGroup.get('thirdPartyTemplatingOption')?.setValue(thirdPartyTemplatingOption, { emitEvent: false }); // do not call thirdPartyTemplatingOptionSubscription
        this.formGroup.get('auxiliaryAccountDynamic')?.setValue(value.auxiliaryAccountDynamic);
        this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(value.auxiliaryAccountLabelDynamic);
      }

      this.formGroup.updateValueAndValidity();
    }
  }

  @Input()
  set fieldsConfiguration (value: ThirdPartyAccountSectionFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;
      this.thirdPartyTemplatingOptions = value.auxiliaryDynamic.options;

      const accountMaxLength = value.account.maxLength != null ? value.account.maxLength : defaultMainAccountMaxLength;
      const accountLabelMaxLength = value.label.maxLength != null ? value.label.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryAccountStaticMaxLength = value.auxiliaryAccountStatic.maxLength != null ? value.auxiliaryAccountStatic.maxLength : defaultAuxiliaryAccountMaxLength;
      const auxiliaryAccountLabelStaticMaxLength = value.auxiliaryLabelStatic.maxLength != null ? value.auxiliaryLabelStatic.maxLength : defaultAccountLabelMaxLength;
      const auxiliaryAccountDynamicMaxLength = value.auxiliaryDynamic.accountMaxLength != null ? value.auxiliaryDynamic.accountMaxLength : defaultDynamicConfigMaxLength;
      const auxiliaryAccountLabelDynamicMaxLength = value.auxiliaryDynamic.labelMaxLength != null ? value.auxiliaryDynamic.labelMaxLength : defaultDynamicConfigMaxLength;

      this.formGroup.get('useSuggestedAccounts')?.setValue(value.useSuggestedAccounts.defaultValue);
      this.formGroup.get('account')?.setValidators([Validators.required, Validators.maxLength(accountMaxLength)]);
      this.formGroup.get('account')?.setValue(value.account.defaultValue);
      this.formGroup.get('accountLabel')?.setValidators([Validators.maxLength(accountLabelMaxLength)]);
      this.formGroup.get('accountLabel')?.setValue(value.label.defaultValue);
      this.formGroup.get('hasDynamic')?.setValue(value.isDynamic.defaultValue);
      this.formGroup.get('auxiliaryAccountStatic')?.setValidators([Validators.maxLength(auxiliaryAccountStaticMaxLength)]);
      this.formGroup.get('auxiliaryAccountLabelStatic')?.setValidators([Validators.maxLength(auxiliaryAccountLabelStaticMaxLength)]);
      this.formGroup.get('auxiliaryAccountDynamic')?.setValidators([Validators.maxLength(auxiliaryAccountDynamicMaxLength)]);
      this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValidators([Validators.maxLength(auxiliaryAccountLabelDynamicMaxLength)]);

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formGroup = new FormGroup({
      useSuggestedAccounts: new FormControl(null, [Validators.required]),
      account: new FormControl('', [Validators.required, Validators.maxLength(defaultMainAccountMaxLength)]),
      accountLabel: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      hasDynamic: new FormControl(false, [Validators.required]),
      thirdPartyTemplatingOption: new FormControl(null),
      auxiliaryAccountStatic: new FormControl('', [Validators.maxLength(defaultAuxiliaryAccountMaxLength)]),
      auxiliaryAccountLabelStatic: new FormControl('', [Validators.maxLength(defaultAccountLabelMaxLength)]),
      auxiliaryAccountDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)]),
      auxiliaryAccountLabelDynamic: new FormControl('', [Validators.maxLength(defaultDynamicConfigMaxLength)])
    });

    this.hasDynamicSubscription = this.formGroup.get('hasDynamic')?.valueChanges.subscribe({
      next: (value) => {
        if (value === true) {
          this.formGroup.get('auxiliaryAccountStatic')?.setValue(null);
          this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(null);
          this.formGroup.get('thirdPartyTemplatingOption')?.setValue(this.fieldsConfig?.auxiliaryDynamic?.defaultOption || null);
        } else {
          this.formGroup.get('thirdPartyTemplatingOption')?.setValue(null);
          this.formGroup.get('auxiliaryAccountStatic')?.setValue(this.fieldsConfig?.auxiliaryAccountStatic?.defaultValue || this.toolLabel);
          this.formGroup.get('auxiliaryAccountLabelStatic')?.setValue(this.fieldsConfig?.auxiliaryLabelStatic?.defaultValue || this.toolLabel);
        }
        this.formGroup.updateValueAndValidity();
      }
    });

    this.thirdPartyTemplatingOptionSubscription = this.formGroup.get('thirdPartyTemplatingOption')?.valueChanges.subscribe((value: ThirdPartyTemplateOption) => {
      this.formGroup.get('auxiliaryAccountDynamic')?.setValue(value?.auxiliaryAccountDynamic || null);
      this.formGroup.get('auxiliaryAccountLabelDynamic')?.setValue(value?.auxiliaryLabelDynamic || null);
      this.formGroup.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.hasDynamicSubscription?.unsubscribe();
    this.thirdPartyTemplatingOptionSubscription?.unsubscribe()
  }

  avoidLineBreak(event: KeyboardEvent) {
    if(event.key == 'Enter' || (event.shiftKey && event.key == 'Enter')) {
      event.preventDefault();
    }
  }

  compareSelectionObjects(object1: ThirdPartyTemplateOption, object2: ThirdPartyTemplateOption): boolean {
    return object1 && object2 && object1.label == object2.label;
  }

}