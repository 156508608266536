import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { InqomState } from '../entities/third-parts-authentication-inqom-state.entity';
import { ThirdPartsAuthenticationInqomManualCreateUpdateRequest } from '../entities/third-parts-authentication-inqom-manual-create-update-request.entity';
import { ThirdPartsAuthenticationList } from '~modules/thirdparts/common/entities/third-parts-authentication-list.entity';
import { AuthenticationCallbackState } from '~modules/thirdparts/common/entities/authentication-callback-state.constant';
import { ThirdPartsAuthenticationInqomCreateUpdateResponse } from '../entities/third-parts-authentication-inqom-create-update-response.entity';

@Injectable()
export class ThirdPartsInqomAuthenticationService {

  constructor(private readonly http: HttpClient) { }

  /**
   * Generate a link to the authenticate page of home.inqom.com and then do a redirection to this link
   * @param state the state to restore the application
   */
  public authorizeInqomApplication(state: string): void {
    const url =
      `${ environment.inqom.oauth2.authorizeUrl }` +
      `?client_id=${ environment.inqom.oauth2.clientId }` +
      `&redirect_uri=${ encodeURIComponent( `${ window.location.origin }/app/third-parts/inqom/callback` ) }` +
      `&response_type=code` +
      `&scope=${ encodeURIComponent( environment.inqom.oauth2.scopes ) }` +
      `&state=${ state }`;

    window.location.href = url;
  }

  /**
   * Create inqom authentication from authorization key
   * @param authorizationCode Authorization code from inqom
   */
  public createUpdateAuthenticationFromAuthorisationCode(authorizationCode: string): Observable<ThirdPartsAuthenticationInqomCreateUpdateResponse> {
    const path = `/api/third-parts/inqom/authentication/create-update-from-authorization-token`;
    return this.http.post<ThirdPartsAuthenticationInqomCreateUpdateResponse>(path, {
      authorizationCode
    });
  }


  /**
   * Save a state with an unique identifier returned
   * @param state state to store
   */
  public generateIdentifierAndCodeChallengeAndSaveState(callbackState: AuthenticationCallbackState): InqomState {
    // Generate identifier (toString(36) converts the number in base 36 (numbers + letters) and so reduce number size)
    const id = Math.random().toString(36);
    const state: InqomState = {
      id,
      state: callbackState
    }
    localStorage.setItem('inqomState', JSON.stringify(state));
    return state;
  }

  /**
   * Return the state saved for inqom
   * @param id id to check
   */
  public getState(): InqomState {
    const stringState = localStorage.getItem('inqomState');
    return stringState != null ? JSON.parse(stringState) : null;
  }

  public list$(): Observable<ThirdPartsAuthenticationList[]> {
    const path = `/api/third-parts/inqom/authentication/list`;
    return this.http.get<ThirdPartsAuthenticationList[]>(path);
  }

  public createOrUpdate$(request: ThirdPartsAuthenticationInqomManualCreateUpdateRequest) {
    const url = `/api/third-parts/inqom/authentication`;

    return this.http.post<void>(url, request);
  }
}
