import { AccountingEntryNumberModeEnum } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

export const fecDownLoadFieldsConfiguration : DownloadFieldsConfiguration = {
    toolLabel: 'fec_dl',
    generatePartialLettering: {
        defaultValue: false,
    },
    accountingEntryNumberMode: {
        defaultValue: AccountingEntryNumberModeEnum.PER_JOURNAL
    }
}