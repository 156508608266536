
<form [formGroup]="formGroup">
  <label for="folderId"><h4><mat-icon>description</mat-icon>&nbsp;Agiris / Isacompta / Isagri</h4></label>
  <mat-form-field>
    <mat-label>Identifiant dossier</mat-label>
    <input matInput formControlName="folderId" placeholder="000001">
  </mat-form-field>
  <ng-container *ngIf="configuration?.accountingEntryNumberMode?.visible !== false">
    <label for="accountingEntryNumberMode"><h4><mat-icon>format_list_numbered</mat-icon>&nbsp;Numéro d'écriture comptable</h4></label>
    <mat-form-field class="full-width">
      <mat-label>Numéro d'écriture comptable</mat-label>
      <mat-select formControlName="accountingEntryNumberMode">
        <mat-option 
            *ngFor="let mode of accountingNumberModeList"
            [value]="mode.value">
            {{mode.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="configuration?.generatePartialLettering?.visible !== false">
    <label for="generatePartialLettering"><h4><mat-icon>account_tree</mat-icon>&nbsp;Lettrage</h4></label>
    <mat-slide-toggle formControlName="generatePartialLettering">
      <div class="text-and-icon-container">
        <span>Générer les lettrages partiels disponibles</span>
      </div>
    </mat-slide-toggle>
  </ng-container>
</form>
