import { Injectable } from "@angular/core";
import { Observable, of, switchMap, take } from "rxjs";
import { CurrentCompanyService } from "~modules/company/services/current-company.service";
import { ExtractConnectorType } from "../models/extract-connector.type";
import { ExtractNodeEnum } from '~modules/connectors/dtos/create-update-global-connector.request';
import { stripeTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/stripe-transformation-fields-configuration';
import { paypalTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/paypal-transformation-fields-configuration';
import { gocardlessTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/gocardless-transformation-fields-configuration';
import { qontoTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/qonto-transformation-fields-configuration';
import { almaTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/alma-transformation-fields-configuration';
import { almaFileRunFieldsConfiguration } from "./extract-connectors-configurations/run-fields/file/alma-file-run-fields-configuration";
import { uberEatsTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/uber-eats-transformation-fields-configuration";
import { uberEatsFileRunFieldsConfiguration } from "./extract-connectors-configurations/run-fields/file/uber-eats-file-run-fields-configuration";
import { apiRunFieldsConfiguration } from "./extract-connectors-configurations/run-fields/api/api-run-fields-configuration";
import { sumUpFileRunFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/run-fields/file/sum-up-file-run-fields-configuration';
import { sumUpTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/sum-up-transformation-fields-configuration';
import { axonautTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/axonaut-transformation-fields-configuration";
import { shopifyTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/shopify-transformation-fields-configuration';
import { shopifyFileRunFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/run-fields/file/shopify-file-run-fields-configuration';
import { cashAndRepairTransformationFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/transformation-fields/cash-and-repair-transformation-fields-configuration';
import { AlmaAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/alma/components/alma-ae-api-exporter-configuration/alma-ae-api-exporter-configuration.component";
import { AxonautAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/axonaut/components/axonaut-ae-api-exporter-configuration/axonaut-ae-api-exporter-configuration.component";
import { GoCardlessAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/gocardless/components/gocardless-ae-api-exporter-configuration/gocardless-ae-api-exporter-configuration.component";
import { PaypalAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/paypal/components/paypal-ae-api-exporter-configuration/paypal-ae-api-exporter-configuration.component";
import { QontoAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/qonto/components/qonto-ae-api-exporter-configuration/qonto-ae-api-exporter-configuration.component";
import { StripeAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/stripe/components/stripe-ae-api-exporter-configuration/stripe-ae-api-exporter-configuration.component";
import { AlmaAccountingEntriesFileExporterConfigurationComponent } from "~modules/thirdparts/alma/components/alma-ae-file-exporter-configuration/alma-ae-file-exporter-configuration.component";
import { UberEatsAccountingEntriesFileExporterConfigurationComponent } from "~modules/thirdparts/ubereats/components/ubereats-ae-file-exporter-configuration/ubereats-ae-file-exporter-configuration.component";
import { SumpUpAccountingEntriesFileExporterConfigurationComponent } from "~modules/thirdparts/sumup/components/sumup-ae-file-exporter-configuration/sumup-ae-file-exporter-configuration.component";
import { CashAndRepairAccountingEntriesApiExporterConfigurationComponent } from '~modules/thirdparts/cash-and-repair/components/cash-and-repair-ae-api-exporter-configuration/cash-and-repair-ae-api-exporter-configuration.component';
import { ShopifyAccountingEntriesFileExporterConfigurationComponent } from '~modules/thirdparts/shopify/components/shopify-ae-file-exporter-configuration/shopify-ae-file-exporter-configuration.component';
import { NextMotionAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/next-motion/components/next-motion-ae-api-exporter-configuration/next-motion-ae-api-exporter-configuration.component";
import { nextMotionTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/next-motion-transformation-fields-configuration";
import { LibeoAccountingEntriesApiExporterConfigurationComponent } from "~modules/thirdparts/libeo/components/libeo-ae-api-exporter-configuration/libeo-ae-api-exporter-configuration.component";
import { libeoTransformationFieldsConfiguration } from "./extract-connectors-configurations/transformation-fields/libeo-transformation-fields-configuration";

export const extractConnectorsNodeList: ExtractConnectorType[] = [
    {
        configurationComponent: StripeAccountingEntriesApiExporterConfigurationComponent,
        id: 'stripe',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/stripe_api_300x300.png',
        label: 'Stripe (API)',
        type: 'STRIPE_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: stripeTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: PaypalAccountingEntriesApiExporterConfigurationComponent,
        id: 'paypal',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/paypal_api_300x300.png',
        label: 'Paypal (API)',
        type: 'PAYPAL_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: paypalTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: GoCardlessAccountingEntriesApiExporterConfigurationComponent,
        id: 'gocardless',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/gocardless_api_300x300.png',
        label: 'Gocardless (API)',
        type: 'GOCARDLESS_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: gocardlessTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: QontoAccountingEntriesApiExporterConfigurationComponent,
        id: 'qonto',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/qonto_api_300x300.png',
        label: 'Qonto (API)',
        type: 'QONTO_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: qontoTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: AlmaAccountingEntriesApiExporterConfigurationComponent,
        id: 'alma',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/alma_api_300x300.png',
        label: 'Alma (API)',
        type: 'ALMA_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: almaTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: AlmaAccountingEntriesFileExporterConfigurationComponent,
        id: 'almafile',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/alma_file_300x300.png',
        label: 'Alma (fichier)',
        type: 'ALMA_FILE_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: almaTransformationFieldsConfiguration,
        runFieldsConfiguration: almaFileRunFieldsConfiguration
    },
    {
        configurationComponent: UberEatsAccountingEntriesFileExporterConfigurationComponent,
        id: 'ubereatsfile',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/uber_file_300x300.png',
        label: 'Uber Eats (fichier)',
        type: 'UBER_EATS_FILE_PAYMENT',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: uberEatsTransformationFieldsConfiguration,
        runFieldsConfiguration: uberEatsFileRunFieldsConfiguration
    },
    {
        configurationComponent: SumpUpAccountingEntriesFileExporterConfigurationComponent,
        id: 'sumupfile',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/sumup_file_300x300.png',
        label: 'SumUp (fichier)',
        type: 'SUM_UP_FILE_ACCOUNTING_ENTRY',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: sumUpTransformationFieldsConfiguration,
        runFieldsConfiguration: sumUpFileRunFieldsConfiguration
    },
    {
        configurationComponent: AxonautAccountingEntriesApiExporterConfigurationComponent,
        id: 'axonaut',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/axonaut_api_300x300.png',
        label: 'Axonaut (API)',
        type: 'AXONAUT_ACCOUNTING_ENTRIES',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: axonautTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: CashAndRepairAccountingEntriesApiExporterConfigurationComponent,
        id: 'cashandrepair',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/cash_and_repair_api_300x300.png',
        label: `Cerebro (Cash and Repair, BeeMyPhone)`,
        type: 'CASH_AND_REPAIR_ACCOUNTING_ENTRIES',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: cashAndRepairTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: ShopifyAccountingEntriesFileExporterConfigurationComponent,
        id: 'shopifyfile',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/shopify_file_300x300.png',
        label: 'Shopify (fichier)',
        type: 'SHOPIFY_FILE_ACCOUNTING_ENTRIES',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: shopifyTransformationFieldsConfiguration,
        runFieldsConfiguration: shopifyFileRunFieldsConfiguration
    },
    {
        configurationComponent: NextMotionAccountingEntriesApiExporterConfigurationComponent,
        id: 'nextmotioncce',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/next_motion_api_300x300.png',
        label: 'Next Motion (CCE)',
        type: 'NEXT_MOTION_CCE_ACCOUNTING_ENTRIES',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: nextMotionTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    },
    {
        configurationComponent: LibeoAccountingEntriesApiExporterConfigurationComponent,
        id: 'nextmotioncce',
        iconUrl: '/assets/img/connectors/extract-nodes/with-icons/libeo_api_300x300.png',
        label: 'Libeo',
        type: 'LIBEO_ACCOUNTING_ENTRIES',
        outputType: 'ACCOUNTING_ENTRIES',
        transformationFieldsConfiguration: libeoTransformationFieldsConfiguration,
        runFieldsConfiguration: apiRunFieldsConfiguration
    }
];

@Injectable()
export class ExtractConnectorsService {

    constructor(
        private readonly currentCompanyService: CurrentCompanyService,
    ) {}

    getOneByType(type: ExtractNodeEnum): ExtractConnectorType | null {
        const connector = this.getList().find(element => element.type === type);
        if (connector == null) {
            return null;
        }
        return connector;
    }

    getAvailableOnes$(): Observable<ExtractConnectorType[]> {
        return this.currentCompanyService.currentConnectorsIncludedExtractNodes$.pipe(
            take(1),
            switchMap(
                extractNodes => of(this.getList().filter((tool) => extractNodes.includes(tool.type)).sort((a, b) => a.label.localeCompare(b.label)))
            )
        );
    }

    getList(): ExtractConnectorType[] {
        return extractConnectorsNodeList;
    }
}
