<form [formGroup]="formGroup">
  <div class="row" *ngIf="fieldsConfig?.useSuggestedAccounts?.visible !== false">
    <mat-slide-toggle formControlName="useSuggestedAccounts">
      <div>
        <span>Utiliser la configuration {{ toolLabel }} des {{thirdPartyTypeLabel}}s</span>
      </div>
    </mat-slide-toggle>
  </div>
  <div class="row dark-info" *ngIf="formGroup.value.useSuggestedAccounts === true">
    <span
      matTooltip="En cas de configuration manquante dans {{ toolLabel }}, la configuration suivante sera utilisée."
      matTooltipPosition="right"
    >
      <b>Configuration de secours</b>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Auxiliarisation des {{thirdPartyTypeLabel}}</mat-label>
    <mat-select formControlName="hasDynamic" class="full-width" *ngIf="fieldsConfig?.isDynamic?.visible !== false">
      <mat-option [value]="false">Compte {{thirdPartyTypeLabel}} centralisateur (unique)</mat-option>
      <mat-option [value]="true">Compte {{thirdPartyTypeLabel}} auxiliarisé</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="row">
    <mat-form-field class="third-width" *ngIf="fieldsConfig?.account?.visible !== false">
      <mat-label>Racine {{thirdPartyTypeLabel}}</mat-label>
      <input matInput formControlName="account" [placeholder]="fieldsConfig?.account?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="two-thirds-width"
    *ngIf="formGroup.value.hasDynamic === false && fieldsConfig?.auxiliaryAccountStatic?.visible !== false">
      <span matTextPrefix class="greyed-prefix">{{formGroup.get('account')?.value || ''}}&nbsp;</span>
      <mat-label>Auxiliaire {{thirdPartyTypeLabel}}</mat-label>
      <input matInput formControlName="auxiliaryAccountStatic" [placeholder]="fieldsConfig?.auxiliaryAccountStatic?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field 
    class="two-thirds-width" 
    *ngIf="formGroup.value.hasDynamic === true && fieldsConfig?.auxiliaryDynamic?.visible !== false">
    <mat-label>Compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <mat-select 
      formControlName="thirdPartyTemplatingOption" 
      [compareWith]="compareSelectionObjects">
        <mat-option *ngFor="let option of thirdPartyTemplatingOptions" [value]="option">{{option.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- *ngIf="false" until we need to get rid of labels -->
    <mat-form-field class="two-thirds-width" *ngIf="false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} racine</mat-label>
      <input matInput formControlName="accountLabel" [placeholder]="fieldsConfig?.label?.defaultValue || ''">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="full-width"
      *ngIf="formGroup.value.hasDynamic === true && fieldsConfig?.auxiliaryDynamic?.visible !== false && this.formGroup.get('thirdPartyTemplatingOption')?.value.showAdvancedTemplateField === true">
      <mat-label>Compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <textarea matInput
      (keypress)="avoidLineBreak($event)"
      formControlName="auxiliaryAccountDynamic"
      [placeholder]="fieldsConfig?.auxiliaryDynamic?.defaultOption?.auxiliaryAccountDynamic || ''"
      cdkTextareaAutosize
      #accountAutosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </div>
  <!-- *ngIf="false" until we need to get rid of labels -->
  <div class="row" *ngIf="false">
    <mat-form-field class="full-width" *ngIf="formGroup.value.hasDynamic === false && fieldsConfig?.auxiliaryLabelStatic?.visible !== false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <input matInput formControlName="auxiliaryAccountLabelStatic" [placeholder]="fieldsConfig?.auxiliaryLabelStatic?.defaultValue || ''">
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="formGroup.value.hasDynamic === true && fieldsConfig?.auxiliaryDynamic?.visible !== false">
      <mat-label>Libellé compte {{thirdPartyTypeLabel}} auxiliaire</mat-label>
      <textarea matInput
      (keypress)="avoidLineBreak($event)"
      formControlName="auxiliaryAccountLabelDynamic"
      [placeholder]="fieldsConfig?.auxiliaryDynamic?.defaultOption?.auxiliaryLabelDynamic || ''"
      cdkTextareaAutosize
      #accountLabelAutosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </div>
</form>