import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VatAccountsSectionFieldsConfiguration, defaultPourcentageMaxValue, defaultPourcentagePatternValue } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { VatAccountConfiguration } from '../../entities/vat-account-configuration.entity';
import { environment } from 'src/environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VatRegimeOptionEnum, vatRegimeOptions } from '~modules/connectors/constants/vat-regime.option';
import { debounceTime, ReplaySubject, Subscription } from 'rxjs';
import { allCountries, euCountries, EUCountryVAT } from '~modules/connectors/constants/country-code-constants';
import { allEUVATRates, euVatRates } from '~modules/connectors/constants/country-vat-rate-constants';

type VatAccountConfigurationControls = FormGroup<{
  id: FormControl<number | null>;
  countryCode: FormControl<string | null>;
  regime: FormControl<VatRegimeOptionEnum | null>;
  rate: FormControl<number | null>;
  account: FormControl<string | null>;
  accountLabel: FormControl<string | null>;
}>;

type VatAccountComparingType = {
  id: number,
  countryCode: string,
  regime: string,
  rate: number
}

type ErrorType = {
  duplicate: string,
  unknownCountry: string
}

@Component({
  selector: 'app-vat-account-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  templateUrl: './vat-account-configuration.component.html',
  styleUrls: ['./vat-account-configuration.component.scss']
})
export class VatAccountConfigurationComponent implements OnDestroy {

  vatRegimeOptions = vatRegimeOptions;

  formGroup!: FormGroup;
  formArray!: FormArray<VatAccountConfigurationControls>;

  fieldsConfig: VatAccountsSectionFieldsConfiguration | undefined;
  fieldsSubscriptions: Subscription[] = [];
  filteredCountryCodeOptions$ = new ReplaySubject<string[]>(1);
  filteredVatOptions$ = new ReplaySubject<number[]>(1);

  @Input()
  toolLabel!: string | undefined;

  @Input()
  addLabel!: string | undefined;

  duplicateErrorLabel?: string;

  get supportEmail() {
    return environment.supportEmail;
  }

  @Input()
  set vatAccounts(value: VatAccountConfiguration[] | undefined) {
    if (value && value.length !== 0) {
      if (this.formArray.controls.length !== 0) {
        this.formArray.clear()
      }
      let defaultAccount = null;
      for (const accountConfiguration of value) {
        if (accountConfiguration.countryCode == null && accountConfiguration.rate == null) {
          if (defaultAccount != null) {
            console.error('There are two or more default configurations');
          }
          defaultAccount = accountConfiguration;
        } else {
          this.formArray.push(
            this.createVatConfigurationGroup(
              accountConfiguration.id,
              accountConfiguration.countryCode,
              accountConfiguration.regime,
              accountConfiguration.rate,
              accountConfiguration.account,
              accountConfiguration.accountLabel
            )
          );
        }
      }

      if (defaultAccount != null) {
        this.formArray.push(
          this.createVatConfigurationGroup(
            defaultAccount.id,
            defaultAccount.countryCode,
            defaultAccount.regime,
            defaultAccount.rate,
            defaultAccount.account,
            defaultAccount.accountLabel
          )
        );
      } else {
        console.error('There is no default configurations');
      }
    }
  }

  @Input()
  set fieldsConfiguration(value: VatAccountsSectionFieldsConfiguration | undefined) {
    this.duplicateErrorLabel = this.getDuplicateErrorLabel(value);
    if (value != undefined) {
      this.fieldsConfig = value;

      if (this.formArray.controls.length === 0) {
        for (const accountConfiguration of value.accounts || []) {
          this.formArray.push(
            this.createVatConfigurationGroup(
              null,
              accountConfiguration.countryCode,
              accountConfiguration.regime,
              accountConfiguration.rate,
              accountConfiguration.account,
              accountConfiguration.accountLabel
            )
          );
        }

        this.formArray.push(
          this.createVatConfigurationGroup(
            null,
            value.defaultAccount.countryCode,
            value.defaultAccount.regime,
            value.defaultAccount.rate,
            value.defaultAccount.account,
            value.defaultAccount.accountLabel,
            true
          )
        );
      } else {
        this.formArray.controls.forEach((vatConfiguration, index) => {
          if ((value.columns?.countryCode?.canEdit != null && !this.fieldsConfig?.columns?.countryCode?.canEdit) || index === this.formArray.controls.length - 1) {
            vatConfiguration.get('countryCode')?.disable();
          } else {
            vatConfiguration.get('countryCode')?.enable();
          }
          if ((value.columns?.regime?.canEdit != null && !this.fieldsConfig?.columns?.regime?.canEdit) || index === this.formArray.controls.length - 1) {
            vatConfiguration.get('regime')?.disable();
          } else {
            vatConfiguration.get('regime')?.enable();
          }
          if ((value.columns?.rate?.canEdit != null && !this.fieldsConfig?.columns?.rate?.canEdit) || index === this.formArray.controls.length - 1) {
            vatConfiguration.get('rate')?.disable();
          } else {
            vatConfiguration.get('rate')?.enable();
          }
          if ((value.columns?.account?.canEdit != null && !this.fieldsConfig?.columns?.account?.canEdit)) {
            vatConfiguration.get('account')?.disable();
          } else {
            vatConfiguration.get('account')?.enable();
          }
          if ((value.columns?.accountLabel?.canEdit != null && !this.fieldsConfig?.columns?.accountLabel?.canEdit)) {
            vatConfiguration.get('accountLabel')?.disable();
          } else {
            vatConfiguration.get('accountLabel')?.enable();
          }
        });
      }

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formArray = new FormArray<VatAccountConfigurationControls>([], [Validators.required]);
    this.formGroup = new FormGroup({
      formArray: this.formArray
    });
    this.filteredCountryCodeOptions$.next(euCountries);
    this.filteredVatOptions$.next(allEUVATRates);
  }
  ngOnDestroy(): void {
    this.fieldsSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addVatAccount() {
    const vatConfigurationGroup: VatAccountConfigurationControls = this.createVatConfigurationGroup(
      null,
      this.fieldsConfig?.columns?.countryCode?.defaultValue || null,
      this.fieldsConfig?.columns?.regime?.defaultValue || null,
      this.fieldsConfig?.columns?.rate?.defaultValue || null,
      this.fieldsConfig?.columns?.account?.defaultValue || null,
      this.fieldsConfig?.columns?.accountLabel?.defaultValue || null
    );
    this.formArray.insert(this.formArray.controls.length - 1, vatConfigurationGroup);
  }

  deleteVatAccount(index: number) {
    this.fieldsSubscriptions.at(index)?.unsubscribe();
    delete this.fieldsSubscriptions[index];
    this.formArray.removeAt(index);
  }

  createVatConfigurationGroup(
    id: number | null = null,
    countryCode: string | null = null,
    regime: VatRegimeOptionEnum | null = null,
    rate: number | null = null,
    account: string | null = null,
    accountLabel: string | null = null,
    isDefault = false
  ): VatAccountConfigurationControls {
    const result: VatAccountConfigurationControls = new FormGroup({
      id: new FormControl<number | null>(id),
      countryCode: new FormControl<string | null>(countryCode, [Validators.minLength(2), Validators.maxLength(2)]),
      regime: new FormControl<VatRegimeOptionEnum | null>(regime),
      rate: new FormControl<number | null>(rate, [Validators.min(0), Validators.max(defaultPourcentageMaxValue), Validators.pattern(defaultPourcentagePatternValue)]),
      account: new FormControl<string | null>(account, [Validators.required]),
      accountLabel: new FormControl<string | null>(accountLabel)
    });
    if ((this.fieldsConfig?.columns?.countryCode?.canEdit != null && !this.fieldsConfig?.columns?.countryCode?.canEdit) || isDefault) {
      result.get('countryCode')?.disable();
    }
    if ((this.fieldsConfig?.columns?.regime?.canEdit != null && !this.fieldsConfig?.columns?.regime?.canEdit) || isDefault) {
      result.get('regime')?.disable();
    }
    if ((this.fieldsConfig?.columns?.rate?.canEdit != null && !this.fieldsConfig?.columns?.rate?.canEdit) || isDefault) {
      result.get('rate')?.disable();
    }
    if ((this.fieldsConfig?.columns?.account?.canEdit != null && !this.fieldsConfig?.columns?.account?.canEdit)) {
      result.get('account')?.disable();
    }
    if ((this.fieldsConfig?.columns?.accountLabel?.canEdit != null && !this.fieldsConfig?.columns?.accountLabel?.canEdit)) {
      result.get('accountLabel')?.disable();
    }
    this.formGroup.markAllAsTouched();
    this.fieldsSubscriptions.push(result.valueChanges.pipe(debounceTime(300)).subscribe((change) => {
      this.filteredCountryCodeOptions$.next(this._filter(euCountries, change.countryCode ?? null));
      const vatRatesForCountry = this.getRatesByCode(change.countryCode as EUCountryVAT);
      this.filteredVatOptions$.next(this._filterNumber(vatRatesForCountry, change.rate ?? null));
      this.checkForVatOptionsDuplicatesAndCountryError(change as VatAccountComparingType, this.formArray.controls.indexOf(result));
    }));
    return result;
  }

  compareFunction(o1: VatRegimeOptionEnum, o2: VatRegimeOptionEnum) {
    return (o1 == null && o2 == null) || (o1 != null && o2 != null && o1 === o2);
  }

  checkForVatOptionsDuplicatesAndCountryError(vatConfiguration: VatAccountComparingType, changeIndex: number): void {
    let errors: ErrorType | null = { duplicate: '', unknownCountry: '' };
    if (vatConfiguration.countryCode != null && vatConfiguration.countryCode != "") {
      if (!allCountries.includes(vatConfiguration.countryCode.toUpperCase())) {
        this.formArray.at(changeIndex).controls['countryCode'].setErrors({});
        errors.unknownCountry = `Le code pays ${vatConfiguration.countryCode} est inconnu`;
      }
    } else {
      errors.unknownCountry = '';
    }

    const duplicateIndexes = this.findDuplicateVatAccountIndexes(this.formArray.controls);
    if (duplicateIndexes.length > 0) {
      errors.duplicate = this.duplicateErrorLabel ?? '';
      duplicateIndexes.forEach((index) => {
        this.formArray.at(index).controls['rate'].setErrors({});
        this.formArray.at(index).controls['regime'].setErrors({});
        this.formArray.at(index).controls['countryCode'].setErrors({});
        this.formArray.controls.forEach((_, index) => {
          if (!duplicateIndexes.includes(index)) {
            this.formArray.at(index).controls['rate'].setErrors(null);
            this.formArray.at(index).controls['regime'].setErrors(null);
            this.formArray.at(index).controls['countryCode'].setErrors(null);
          }
        });
      });
    } else {
      errors.duplicate = '';
      this.formArray.controls.forEach((_, index) => {
        this.formArray.at(index).controls['rate'].setErrors(null);
        this.formArray.at(index).controls['regime'].setErrors(null);
        this.formArray.at(index).controls['countryCode'].setErrors(null);
      });
    }
    if (errors.duplicate == '' && errors.unknownCountry == '') {
      errors = null;
    }
    this.formArray.setErrors(errors);

  }

  findDuplicateVatAccountIndexes(
    accounts: VatAccountConfigurationControls[]
  ): number[] {
    const duplicateIndexes: Set<number> = new Set();

    for (let i = 0; i < accounts.length; i++) {
      const { countryCode: countryCode1, regime: regime1, rate: rate1 } = accounts[i].value;

      for (let j = i + 1; j < accounts.length; j++) {
        const { countryCode: countryCode2, regime: regime2, rate: rate2 } = accounts[j].value;

        if ((countryCode1 === countryCode2 && regime1 === regime2 && rate1 === rate2) ||
          (countryCode1 == null && regime1 == null && rate1 == null && countryCode2 == null && regime2 == null && rate2 == null)
        ) {
          duplicateIndexes.add(i);
          duplicateIndexes.add(j);
        }
      }
    }

    return Array.from(duplicateIndexes);
  }

  private _filter(array: string[], value: string | null): string[] {
    return value != null ? array.filter(option => option.toLowerCase().includes(value.toLowerCase())) : array;
  }

  private _filterNumber(array: number[], value: number | null): number[] {
    return value != null ? array.filter(option => option.toString().includes(value.toString())) : array;
  }

  resetCountryCodeOptions(): void {
    this.filteredCountryCodeOptions$.next(euCountries);
  }

  getRatesByCode(code: EUCountryVAT | null | undefined): number[] {
    if (code === null) {
      return allEUVATRates;
    }
    const entry = euVatRates.find(rate => rate.code === code);
    return entry ? entry.rates : allEUVATRates;
  }

  // when we click on a rate or country field, options are refreshed to see all options in case we want to change it
  updateRateAndCountryOptionsOnFocus(focusedFieldIndex: number): void {
    this.filteredCountryCodeOptions$.next(euCountries);
    const linkedCountryCode = this.formArray.controls.at(focusedFieldIndex)?.controls['countryCode'].value;
    const rates = this.getRatesByCode(linkedCountryCode as EUCountryVAT);
    this.filteredVatOptions$.next(rates);
  }

  getDuplicateErrorLabel(config: VatAccountsSectionFieldsConfiguration | undefined): string {
    if (config) {
      const columns = config.columns;
      if ( columns?.regime?.visible === false && columns?.countryCode?.visible === false ) {
        return 'Il n’est pas possible d’avoir plusieurs lignes avec le même taux de TVA.';
      } else if ( columns?.countryCode?.visible === false ) {
        return 'Il n’est pas possible d’avoir plusieurs lignes avec la même association Regime\u00A0+\u00A0Taux de TVA.';
      } else if ( columns?.regime?.visible === false ) {
        return 'Il n’est pas possible d’avoir plusieurs lignes avec la même association Pays\u00A0+\u00A0Taux de TVA.';
      } else {
        return 'Il n’est pas possible d’avoir plusieurs lignes avec la même association Pays\u00A0+\u00A0Regime\u00A0+\u00A0Taux de TVA.';
      }
    } else {
      return 'Il n’est pas possible d’avoir plusieurs lignes identiques';
    }
  }

}