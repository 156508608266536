import { Injectable } from "@angular/core";

export enum AccountingEntryNumberModeEnum {
    NONE = 'NONE',
    PER_JOURNAL = 'PER_JOURNAL',
    GLOBAL = 'GLOBAL'
  }

export type AccountingEntryNumberModeType = {
    label: string;
    value: keyof typeof AccountingEntryNumberModeEnum;
};

@Injectable()
export class AccountingEntryNumberModeService {

    private readonly accountingNumberModeList: AccountingEntryNumberModeType[] = [
        {
            label: "Pas de numéro d'écriture comptable",
            value: "NONE"
        },
        {
            label: "Attribuer un numéro unique aux écritures comptables par journal",
            value: "PER_JOURNAL"
        },
        // {
        //     label: "Attribuer un numéro unique aux écritures comptables tous journaux confondus",
        //     value: "GLOBAL"
        // },
    ];

    public getAll(): AccountingEntryNumberModeType[] {
        return [...this.accountingNumberModeList]; // to avoid side effects
    }
}