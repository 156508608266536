<ng-container [formGroup]="formGroup">
  <ng-container formArrayName="formArray">
    <div class="row danger-info" *ngIf="fieldsConfig?.columns?.countryCode?.canEdit === false && fieldsConfig?.columns?.countryCode?.defaultValue != null">
      <small>{{ toolLabel }} est configuré pour le pays {{ fieldsConfig?.columns?.countryCode?.defaultValue }}. Si vous utilisez {{ toolLabel }} dans un autre pays, merci de nous <a href="mailto:{{supportEmail}}">contacter</a>.</small>
    </div>
    <div class="header-row">
      <div class="header country-code" *ngIf="fieldsConfig?.columns?.countryCode?.visible !== false">Pays</div>
      <div class="header regime" *ngIf="fieldsConfig?.columns?.regime?.visible !== false">Regime</div>
      <div class="header rate">TVA</div>
      <div
        class="header"
        [ngClass]="{
          'account': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible !== false,
          'account-without-country-code': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible !== false,
          'account-without-regime': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible === false,
          'account-without-country-code-and-regime': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible === false
        }"
      >Compte</div>
      <div 
        class="header"
        [ngClass]="{
          'account-label': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible !== false,
          'account-label-without-country-code': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible !== false,
          'account-label-without-regime': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible === false,
          'account-label-without-country-code-and-regime': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible === false
        }"
      >Label compte</div>
    </div>
    <ng-container *ngFor="let account of formArray.controls; index as i" [formGroupName]="i">
      <div class="row flex-center">
        <ng-container *ngIf="i === formArray.controls.length - 1">
          <div [ngClass]="{
              'default': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible !== false,
              'default-without-country-code': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible !== false,
              'default-without-regime': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible === false,
              'default-without-country-code-and-regime': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible === false
            }"
            matTooltipPosition="above"
            matTooltip="Le compte suivant sera utilisé par défaut si aucun autre compte ne correspond."
          >Défaut :</div>
        </ng-container>
        <ng-container *ngIf="i !== formArray.controls.length - 1">
          <mat-form-field *ngIf="fieldsConfig?.columns?.countryCode?.visible !== false" class="country-code">
            <input matInput
                   formControlName="countryCode"
                   [matAutocomplete]="autoCountryCode"
                   autocomplete="off"
                   placeholder="TOUS"
                   (focus)="updateRateAndCountryOptionsOnFocus(i)"
                   (keydown.enter)="$event.preventDefault()"
            >
            <mat-autocomplete #autoCountryCode="matAutocomplete" [panelWidth]="'100px'">
              <mat-option *ngIf="fieldsConfig?.columns?.rate?.visible !== false || fieldsConfig?.columns?.regime?.visible !== false" [value]="null">TOUS</mat-option>
              <mat-option *ngFor="let option of filteredCountryCodeOptions$ | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          
          <mat-form-field *ngIf="fieldsConfig?.columns?.regime?.visible !== false" class="regime">
            <mat-select formControlName="regime" [compareWith]="compareFunction">
              <mat-option *ngIf="fieldsConfig?.columns?.rate?.visible !== false || fieldsConfig?.columns?.countryCode?.visible !== false" [value]="null">TOUS</mat-option>
              <mat-option
                *ngFor="let option of vatRegimeOptions"
                [value]="option.value"
                matTooltipPosition="right"
                [matTooltip]="option.tooltip"
              >{{option.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field ngClass="rate">
            <input matInput
                   formControlName="rate"
                   [matAutocomplete]="autoRate"
                   type="number"
                   autocomplete="off"
                   placeholder="TOUS"
                   (keydown.enter)="$event.preventDefault()"
                   (focus)="updateRateAndCountryOptionsOnFocus(i)">
            <mat-autocomplete #autoRate="matAutocomplete">
              <mat-option *ngIf="fieldsConfig?.columns?.regime?.visible !== false || fieldsConfig?.columns?.countryCode?.visible !== false" [value]="null">TOUS</mat-option>
              <mat-option *ngFor="let option of filteredVatOptions$ | async" [value]="option">
                {{ option.toLocaleString() }}
              </mat-option>
            </mat-autocomplete>
            <span matSuffix>%</span>
          </mat-form-field>
        </ng-container>
        <mat-form-field
          [ngClass]="{
            'account': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible !== false,
            'account-without-country-code': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible !== false,
            'account-without-regime': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible === false,
            'account-without-country-code-and-regime': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible === false
          }"
        >
          <input matInput
                  formControlName="account"
                  autocomplete="off"
                  (keydown.enter)="$event.preventDefault()">
        </mat-form-field>
        <mat-form-field
          [ngClass]="{
            'account-label': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible !== false,
            'account-label-without-country-code': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible !== false,
            'account-label-without-regime': fieldsConfig?.columns?.countryCode?.visible !== false && fieldsConfig?.columns?.regime?.visible === false,
            'account-label-without-country-code-and-regime': fieldsConfig?.columns?.countryCode?.visible === false && fieldsConfig?.columns?.regime?.visible === false
          }"
        >
          <input matInput
                  formControlName="accountLabel"
                  autocomplete="off"
                  (keydown.enter)="$event.preventDefault()">
        </mat-form-field>
        <button mat-button (click)="deleteVatAccount(i)" [disabled]="fieldsConfig?.canDelete === false || i === formArray.controls.length - 1">
          <mat-icon>delete_forever</mat-icon>
        </button>
    </div>
    </ng-container>
  </ng-container>
  <span class="text-error" *ngIf="formArray.errors">
    {{formArray.errors['unknownCountry']}}
  </span>
  <span class="text-error" *ngIf="formArray.errors">{{formArray.errors['duplicate']}}</span>
</ng-container>
<button mat-stroked-button (click)="addVatAccount()" *ngIf="fieldsConfig?.canAdd !== false"><mat-icon>add</mat-icon>&nbsp;{{ addLabel || 'Ajouter une TVA' }}</button>