import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentDescriptionFieldOptions, getSellInvoiceDescriptionFieldOptions, thirdPartTemplatingNameFieldOption, thirdPartyTemplatingOptions } from './default-transformation-fields';
import { AnalyticRuleTypeEnum } from '~modules/connectors/constants/analytic-rule-type.option';

const nextMotionPaymentDescriptionOptions: DescriptionOption[] = getPaymentDescriptionFieldOptions({
    withThirdPart: true
});

const nextMotionSellInvoiceDescriptionOptions: DescriptionOption[] = getSellInvoiceDescriptionFieldOptions({
    withThirdPart: true
});

export const nextMotionTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Next Motion',

    paymentProcessorConfiguration: {
        journalOption: {
            defaultValue: JournalOptionEnum.BY_PAYMENT_METHOD,
        },
        uniqueJournal: {
            code: {
                defaultValue: 'CAI'
            },
            label: {
                defaultValue: 'Caisse'
            }
        },
        client: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            visible: false,
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        methods: {
            unique: false,
            paymentMethodOptions: [
                'ESPECES',
                'VIREMENT BANCAIRE',
                'CARTE BANCAIRE',
                'CHEQUE',
                'BON DE REDUCTION',
                'AUTRE',
                'Alma',
                'HaV dr'
            ],
            defaultMethod: {
                name: null,
                export: true,
                journalCode: 'PND',
                internalTransfer: {
                    account: null,
                    label: null
                },
                provider: {
                    account: '5899',
                    label: null
                }
            },
            methods: [
                {
                    name: 'ESPECES',
                    export: true,
                    journalCode: 'CAI',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5821',
                        label: null
                    }
                },
                {
                    name: 'VIREMENT BANCAIRE',
                    export: true,
                    journalCode: 'VIR',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '582',
                        label: null
                    }
                },
                {
                    name: 'CARTE BANCAIRE',
                    export: true,
                    journalCode: 'CAR',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '581',
                        label: null
                    }
                },
                {
                    name: 'CHEQUE',
                    export: true,
                    journalCode: 'CHQ',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5112',
                        label: null
                    }
                },
                {
                    name: 'BON DE REDUCTION',
                    export: true,
                    journalCode: 'GIFT',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5861',
                        label: null
                    }
                },
                {
                    name: 'AUTRE',
                    export: true,
                    journalCode: 'PND',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5899',
                        label: null
                    }
                },
                {
                    name: 'Alma',
                    export: true,
                    journalCode: 'CBA',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '583',
                        label: null
                    }
                },
                {
                    name: 'HaV dr',
                    export: true,
                    journalCode: 'HON',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '589',
                        label: null
                    }
                }
            ]
        },
        providerFee: {
            visible: false,
            account: {
                defaultValue: '6222'
            },
            label: {
                defaultValue: 'Commissions et courtages sur ventes'
            }
        },
        deductibleVat20: {
            visible: false,
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        description: {
            options: nextMotionPaymentDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ transaction.type }} • {{ transaction.subTypeId | default(transaction.transactionId) }} • {{ transaction.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VT'
            },
            label: {
                defaultValue: 'Ventes'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                defaultValue: true
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: true
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: thirdPartyTemplatingOptions,
                defaultOption: thirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false
                        },
                        regime: {
                            visible: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: []
                }
            },
            oneLinePerVatRate: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false
                        },
                        regime: {
                            visible: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: []
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            visible: false,
            defaultValue: false
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false
                },
                regime: {
                    visible: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA collectée à reclasser'
            },
            accounts: [
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 5.5,
                    account: '44571055',
                    accountLabel: 'TVA collectée 5,5%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 10,
                    account: '44571010',
                    accountLabel: 'TVA collectée 10%'
                },
                {
                    countryCode: 'FR',
                    regime: null,
                    rate: 20,
                    account: '44571020',
                    accountLabel: 'TVA collectée 20%'
                }
            ]
        },
        amountDifferenceOption: {
            visible: false,
            defaultValue: InvoiceAmountDifferenceOptionEnum.USE_ACCOUNTS
        },
        debitAdjustment: {
            visible: false,
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            visible: false,
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        description: {
            options: nextMotionSellInvoiceDescriptionOptions,
            descriptionTemplate: {
                defaultValue: '{{ invoice.type }} • {{ invoice.invoiceFunctionalId | default(invoice.invoiceId) }} • {{ invoice.creationDate | date("yyyy-MM-dd") }}'
            }
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: true
        },
        accountRules: {
            accounts: [
                {
                    type: AnalyticRuleTypeEnum.ALL,
                    account: null,
                    axis: 'LOCALISATION',
                    tag: '',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70601',
                    axis: 'PRESTATION',
                    tag: 'EPIL',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70602',
                    axis: 'PRESTATION',
                    tag: 'BALL',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70603',
                    axis: 'PRESTATION',
                    tag: 'CHEV',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70604',
                    axis: 'PRESTATION',
                    tag: 'CHIR',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70606',
                    axis: 'PRESTATION',
                    tag: 'MED',
                    value: 100
                },
                {
                    type: AnalyticRuleTypeEnum.START_WITH,
                    account: '70607',
                    axis: 'PRESTATION',
                    tag: 'DENT',
                    value: 100
                }
            ]
        }
    }
}