import { InvoiceGenerationOptionEnum } from '~modules/connectors/constants/Invoice-generation.option';
import { InvoiceAmountDifferenceOptionEnum } from '~modules/connectors/constants/invoice-amount-difference.option';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { ReverseChargeAggregateOptionEnum } from '~modules/connectors/constants/reverse-charge-aggregate.options';
import { VatRegimeOptionEnum } from '~modules/connectors/constants/vat-regime.option';
import { AccountingEntriesFieldsConfiguration, DescriptionOption } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { getPaymentEntryLineDescriptionFieldOptions, getSellInvoiceEntryLineDescriptionFieldOptions, clientThirdPartyTemplatingOptions, clientThirdPartTemplatingNameFieldOption, getPaymentEntryDescriptionFieldOptions, getSellInvoiceEntryDescriptionFieldOptions, defaultEntryDescriptionEmptyFieldOption, defaultEntryLineDescriptionStandardFieldOption, supplierThirdPartyTemplatingOptions, supplierThirdPartTemplatingNameFieldOption } from './default-transformation-fields';
import { DocumentRefOptionEnum, getDocumentRefOptions } from '~modules/connectors/constants/document-ref.option';

const cashAndRepairSalesPaymentEntryDescriptionOptions: DescriptionOption[] = getPaymentEntryDescriptionFieldOptions({
    withThirdPart: true
});

const cashAndRepairSalesPaymentEntryLineDescriptionOptions: DescriptionOption[] = getPaymentEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

const cashAndRepairSalesSellInvoiceEntryDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryDescriptionFieldOptions({
    withThirdPart: true
});

const cashAndRepairSalesSellInvoiceEntryLineDescriptionOptions: DescriptionOption[] = getSellInvoiceEntryLineDescriptionFieldOptions({
    withThirdPart: true
});

export const cashAndRepairTransformationFieldsConfiguration: AccountingEntriesFieldsConfiguration = {
    toolLabel: 'Cash & Repair',

    paymentProcessorConfiguration: {
        journalOption: {
            defaultValue: JournalOptionEnum.BY_PAYMENT_METHOD,
        },
        uniqueJournal: {
            code: {
                defaultValue: 'CAI'
            },
            label: {
                defaultValue: 'Caisse'
            }
        },
        client: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: clientThirdPartyTemplatingOptions,
                defaultOption: clientThirdPartTemplatingNameFieldOption
            }
        },
        supplier: {
            visible: false,
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '401'
            },
            label: {
                defaultValue: 'Fournisseurs'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: supplierThirdPartyTemplatingOptions,
                defaultOption: supplierThirdPartTemplatingNameFieldOption
            }
        },
        useSuggestedProviderAccounts: {
            visible: false,
            defaultValue: false
        },
        methods: {
            unique: false,
            paymentMethodOptions: [
                'ESPECES',
                'VIREMENT BANCAIRE',
                'CARTE BANCAIRE',
                'CHEQUE',
                'ALMA X3',
                'UP2PAY',
                'BONUS REPARATION'
            ],
            internalTransferVisibleFor: ['CARTE BANCAIRE'],
            defaultMethod: {
                name: null,
                export: true,
                journalCode: 'OD',
                internalTransfer: {
                    account: null,
                    label: null
                },
                provider: {
                    account: '51299',
                    label: null
                }
            },
            methods: [
                {
                    name: 'ESPECES',
                    export: true,
                    journalCode: 'CAI',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '531',
                        label: null
                    }
                },
                {
                    name: 'VIREMENT BANCAIRE',
                    export: true,
                    journalCode: 'VIR',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '580',
                        label: null
                    }
                },
                {
                    name: 'CARTE BANCAIRE',
                    export: true,
                    journalCode: 'CB',
                    internalTransfer: {
                        account: '580',
                        label: null
                    },
                    provider: {
                        account: '580',
                        label: null
                    }
                },
                {
                    name: 'CHEQUE',
                    export: true,
                    journalCode: 'CHQ',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5112',
                        label: null
                    }
                },
                {
                    name: 'ALMA X3',
                    export: true,
                    journalCode: 'ALMA',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5171',
                        label: null
                    }
                },
                {
                    name: 'UP2PAY',
                    export: true,
                    journalCode: 'WEB',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '5172',
                        label: null
                    }
                },
                {
                    name: 'BONUS REPARATION',
                    export: true,
                    journalCode: 'QURP',
                    internalTransfer: {
                        account: null,
                        label: null
                    },
                    provider: {
                        account: '411QUALIREPA',
                        label: null
                    }
                }
            ]
        },
        providerFee: {
            visible: false,
            account: {
                defaultValue: '6222'
            },
            label: {
                defaultValue: 'Commissions et courtages sur ventes'
            }
        },
        deductibleVat20: {
            visible: false,
            account: {
                defaultValue: '44566'
            },
            label: {
                defaultValue: 'TVA sur autres biens et services'
            }
        },
        reverseCharge: {
            visible: false,
            has: {
                defaultValue: false
            },
            aggregateOption: {
                defaultValue: ReverseChargeAggregateOptionEnum.EACH
            },
            collectedIntracomVat: {
                account: {
                    defaultValue: '4452'
                },
                label: {
                    defaultValue: 'TVA due intracommunautaire'
                }
            },
            deductibleIntracomVat: {
                account: {
                    defaultValue: '445662'
                },
                label: {
                    defaultValue: 'TVA intracommunautaire sur autres biens et services'
                }
            },
            vatRate: {
                defaultValue: 20
            }
        },
        undefinedCredit: {
            visible: false,
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        undefinedDebit: {
            visible: false,
            account: {
                defaultValue: '471'
            },
            label: {
                defaultValue: 'Opérations à reclasser'
            }
        },
        entryDescription: {
            options: cashAndRepairSalesPaymentEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption,
        },
        entryLineDescription: {
            options: cashAndRepairSalesPaymentEntryLineDescriptionOptions,
            defaultOption: defaultEntryLineDescriptionStandardFieldOption
        }
    },

    sellInvoiceProcessorConfiguration: {
        journal: {
            code: {
                defaultValue: 'VT'
            },
            label: {
                defaultValue: 'Ventes'
            }
        },
        thirdPartyAccount: {
            useSuggestedAccounts: {
                visible: false,
                defaultValue: false
            },
            account: {
                defaultValue: '411'
            },
            label: {
                defaultValue: 'Clients'
            },
            isDynamic: {
                defaultValue: false
            },
            auxiliaryLabelStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryAccountStatic: {
                defaultValue: 'CAISSE'
            },
            auxiliaryDynamic: {
                options: clientThirdPartyTemplatingOptions,
                defaultOption: clientThirdPartTemplatingNameFieldOption
            }
        },
        generation: {
            useSuggestedProductsAccounts: {
                visible: false,
                defaultValue: true
            },
            option: {
                defaultValue: InvoiceGenerationOptionEnum.ONE_LINE_PER_INVOICE_LINE
            },
            oneLinePerInvoiceLine: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: VatRegimeOptionEnum.CLASSIC,
                            rate: 20,
                            account: '701100',
                            accountLabel: 'Produit TVA classique'
                        },
                        {
                            countryCode: null,
                            regime: VatRegimeOptionEnum.MARGIN,
                            rate: 20,
                            account: '701200',
                            accountLabel: 'Produit TVA sur Marge'
                        }
                    ]
                }
            },
            oneLinePerVatRate: {
                productVats: {
                    columns: {
                        countryCode: {
                            visible: false
                        }
                    },
                    defaultAccount: {
                        countryCode: null,
                        regime: null,
                        rate: null,
                        account: '701099',
                        accountLabel: 'Produits à reclasser'
                    },
                    accounts: [
                        {
                            countryCode: null,
                            regime: VatRegimeOptionEnum.CLASSIC,
                            rate: 20,
                            account: '701000',
                            accountLabel: 'Produit TVA classique'
                        },
                        {
                            countryCode: null,
                            regime: VatRegimeOptionEnum.MARGIN,
                            rate: 20,
                            account: '701055',
                            accountLabel: 'Produit TVA sur Marge'
                        }
                    ]
                }
            },
            oneGlobalLine: {
                product: {
                    account: {
                        defaultValue: '701000'
                    },
                    label: {
                        defaultValue: 'Produit'
                    }
                }
            }
        },
        useSuggestedVatsAccounts: {
            visible: false,
            defaultValue: false
        },
        collectedVats: {
            columns: {
                countryCode: {
                    visible: false
                }
            },
            defaultAccount: {
                countryCode: null,
                regime: null,
                rate: null,
                account: '44571099',
                accountLabel: 'TVA collectée à reclasser'
            },
            accounts: [
                {
                    countryCode: null,
                    regime: VatRegimeOptionEnum.CLASSIC,
                    rate: 20,
                    account: '4457110',
                    accountLabel: 'TVA Classique collectée'
                },
                {
                    countryCode: null,
                    regime: VatRegimeOptionEnum.MARGIN,
                    rate: 20,
                    account: '4457120',
                    accountLabel: 'TVA sur Marge collectée'
                }
            ]
        },
        amountDifferenceOption: {
            visible: false,
            defaultValue: InvoiceAmountDifferenceOptionEnum.ADJUST_HT
        },
        debitAdjustment: {
            visible: false,
            account: {
                defaultValue: "658"
            },
            label: {
                defaultValue: "Charges diverses de gestion courante"
            }
        },
        creditAdjustment: {
            visible: false,
            account: {
                defaultValue: "758"
            },
            label: {
                defaultValue: "Produits divers de gestion courante"
            }
        },
        documentRef: {
            visible: false,
            options: getDocumentRefOptions(),
            defaultValue: DocumentRefOptionEnum.INVOICE_FUNCTIONAL_ID
        },
        entryDescription: {
            options: cashAndRepairSalesSellInvoiceEntryDescriptionOptions,
            defaultOption: defaultEntryDescriptionEmptyFieldOption,
        },
        entryLineDescription: {
            options: cashAndRepairSalesSellInvoiceEntryLineDescriptionOptions,
            defaultOption: defaultEntryLineDescriptionStandardFieldOption,
        }
    },

    analyticProcessorConfiguration: {
        isEnabled: {
            defaultValue: false
        },
        accountRules: {}
    }
}