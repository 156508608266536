import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ThirdPartsInqomAuthenticationService } from './inqom/services/third-parts-inqom-authentication.service';
import { CommonThirdPartsAuthenticationService } from './common/services/common-third-parts-authentication.service';
import { ThirdPartsStripeAuthenticationService } from './stripe/services/third-parts-stripe-authentication.service';
import { ThirdPartsPaypalAuthenticationService } from './paypal/services/third-parts-paypal-authentication.service';
import { ThirdPartsGoCardlessAuthenticationService } from './gocardless/services/third-parts-gocardless-authentication.service';
import { ThirdPartsQontoAuthenticationService } from './qonto/services/third-parts-qonto-authentication.service';
import { ThirdPartsAlmaAuthenticationService } from '~modules/thirdparts/alma/services/third-parts-alma-authentication.service';
import { ThirdPartsAxonautAuthenticationService } from './axonaut/services/third-parts-axonaut-authentication.service';
import { ThirdPartsCashAndRepairAuthenticationService } from '~modules/thirdparts/cash-and-repair/services/third-parts-cash-and-repair-authentication.service';
import { ThirdPartsNextMotionAuthenticationService } from './next-motion/services/third-parts-next-motion-authentication.service';


@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class ThirdPartsModule {
  static forRoot(): ModuleWithProviders<ThirdPartsModule> {
    return {

      ngModule: ThirdPartsModule,
      providers: [
        // common
        CommonThirdPartsAuthenticationService,
        // inqom
        ThirdPartsInqomAuthenticationService,
        // stripe
        ThirdPartsStripeAuthenticationService,
        // paypal
        ThirdPartsPaypalAuthenticationService,
        // gocardless
        ThirdPartsGoCardlessAuthenticationService,
        // qonto
        ThirdPartsQontoAuthenticationService,
        // alma
        ThirdPartsAlmaAuthenticationService,
        // Axonaut
        ThirdPartsAxonautAuthenticationService,
        // Cash and repair
        ThirdPartsCashAndRepairAuthenticationService,
        // Next Motion
        ThirdPartsNextMotionAuthenticationService
      ],
    };
   }
}
