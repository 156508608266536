import { AccountingEntryNumberModeEnum } from '~modules/connectors/modules/load/common/services/accounting-number-mode.service';
import { DownloadFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';

export const zipAsciiDownLoadFieldsConfiguration : DownloadFieldsConfiguration = {
    toolLabel: 'zip_ascii_dl',
    generatePartialLettering: {
        visible: false,
        defaultValue: false
    },
    accountingEntryNumberMode: {
        visible: false,
        defaultValue: AccountingEntryNumberModeEnum.NONE
    }
}