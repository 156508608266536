import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { JournalOptionEnum } from '~modules/connectors/constants/journal.options';
import { PaymentMethodsSectionFieldsConfiguration } from '~modules/connectors/services/extract-connectors-configurations/field-type';
import { PaymentMethodConfiguration } from '../../entities/payment-method-configuration.entity';

import { debounceTime, ReplaySubject, Subscription } from 'rxjs';

export type PaymentMethodConfigurationControls = FormGroup<{
  id: FormControl<number | null>;
  name: FormControl<string | null>;
  export: FormControl<boolean | null>;
  journalCode: FormControl<string | null>;
  internalTransferAccount: FormControl<string | null>;
  internalTransferAccountLabel: FormControl<string | null>;
  providerAccount: FormControl<string | null>;
  providerAccountLabel: FormControl<string | null>;
  bankSupplierAccount: FormControl<string | null>;
  bankSupplierAccountLabel: FormControl<string | null>;
  bankSupplierAuxiliaryAccount: FormControl<string | null>;
  bankSupplierAuxiliaryAccountLabel: FormControl<string | null>;
}>;

@Component({
  selector: 'app-payment-method-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  templateUrl: './payment-method-configuration.component.html',
  styleUrls: ['./payment-method-configuration.component.scss']
})
export class PaymentMethodConfigurationComponent implements OnInit, OnDestroy {

  formGroup!: FormGroup;
  formArray!: FormArray<PaymentMethodConfigurationControls>;

  internalTransferSubscriptions: Subscription[] = [];
  formArraySubscription?: Subscription;
  filteredOptions$ = new ReplaySubject<string[]>(1);
  paymentOptions!: string[];

  fieldsConfig: PaymentMethodsSectionFieldsConfiguration | undefined;
  journalOptionValue: JournalOptionEnum | undefined = undefined;

  @Input()
  set journalOption(value: JournalOptionEnum | undefined) {
    this.journalOptionValue = value;
    if (value) {
      if (value === JournalOptionEnum.UNIQUE) {
        this.formArray.controls.forEach(methodConfiguration => {
          methodConfiguration.get('journalCode')?.setValidators([]);
          methodConfiguration.get('journalCode')?.setValue(null);
        });
      } else {
        this.formArray.controls.forEach(methodConfiguration => {
          methodConfiguration.get('journalCode')?.setValidators([Validators.required]);
          const actualValue: string | undefined | null = methodConfiguration.get('journalCode')?.value;
          methodConfiguration.get('journalCode')?.setValue(actualValue || this.fieldsConfig?.columns?.journalCode?.defaultValue || '');
        });
      }
    }
  }

  get isJournalVisible() {
    return this.journalOptionValue == undefined || this.journalOptionValue === JournalOptionEnum.BY_PAYMENT_METHOD;
  }

  @Input()
  toolLabel!: string | undefined;

  @Input()
  addLabel!: string | undefined;
  @Input()
  headerAccountLabel!: string | undefined;
  @Input()
  inputProviderAccountLabel!: string | undefined;
  @Input()
  defaultTooltipLabel!: string | undefined;

  get supportEmail() {
    return environment.supportEmail;
  }

  @Input()
  set methods(value: PaymentMethodConfiguration[] | undefined) {
    if (value) {
      if (this.formArray.controls.length !== 0) {
        this.formArray.clear()
      }
      let defaultMethod = null;
      for(const methodConfiguration of value) {
        if (methodConfiguration.name == null) {
          if (defaultMethod != null) {
            console.error('There are two or more default methods');
          }
          defaultMethod = methodConfiguration;
        } else {
          this.formArray.push(
            this.createPaymentMethodConfigurationGroup(
              methodConfiguration.id,
              methodConfiguration.name,
              methodConfiguration.export,
              methodConfiguration.journalCode,
              methodConfiguration.internalTransferAccount,
              methodConfiguration.internalTransferAccountLabel,
              methodConfiguration.providerAccount,
              methodConfiguration.providerAccountLabel,
              methodConfiguration.bankSupplierAccount,
              methodConfiguration.bankSupplierAccountLabel,
              methodConfiguration.bankSupplierAuxiliaryAccount,
              methodConfiguration.bankSupplierAuxiliaryAccountLabel
            )
          );
        }
      }

      if (defaultMethod != null) {
        this.formArray.push(
          this.createPaymentMethodConfigurationGroup(
           defaultMethod.id,
           defaultMethod.name,
           defaultMethod.export,
           defaultMethod.journalCode,
           defaultMethod.internalTransferAccount,
           defaultMethod.internalTransferAccountLabel,
           defaultMethod.providerAccount,
           defaultMethod.providerAccountLabel,
           defaultMethod.bankSupplierAccount,
           defaultMethod.bankSupplierAccountLabel,
           defaultMethod.bankSupplierAuxiliaryAccount,
           defaultMethod.bankSupplierAuxiliaryAccountLabel,
           true
          )
        );
      } else {
        console.error('There is no default methods');
      }
    }
  }

  @Input()
  set fieldsConfiguration (value: PaymentMethodsSectionFieldsConfiguration | undefined) {
    if (value != undefined) {
      this.fieldsConfig = value;
      this.paymentOptions = this.fieldsConfig?.paymentMethodOptions ?? [];
      this.filteredOptions$.next(this.paymentOptions);
      if (this.formArray.controls.length === 0) {
        for(const methodConfiguration of value.methods || []) {
          this.formArray.push(
            this.createPaymentMethodConfigurationGroup(
              null,
              methodConfiguration.name,
              methodConfiguration.export,
              methodConfiguration.journalCode,
              methodConfiguration.internalTransfer?.account,
              methodConfiguration.internalTransfer?.label,
              methodConfiguration.provider?.account,
              methodConfiguration.provider?.label,
              methodConfiguration.bankSupplier?.account,
              methodConfiguration.bankSupplier?.label,
              methodConfiguration.bankSupplier?.auxiliaryAccount,
              methodConfiguration.bankSupplier?.auxiliaryLabel
            )
          );
        }

        this.formArray.push(
          this.createPaymentMethodConfigurationGroup(
            null,
            value.defaultMethod.name,
            value.defaultMethod.export,
            value.defaultMethod.journalCode,
            value.defaultMethod.internalTransfer?.account,
            value.defaultMethod.internalTransfer?.label,
            value.defaultMethod.provider?.account,
            value.defaultMethod.provider?.label,
            value.defaultMethod.bankSupplier?.account,
            value.defaultMethod.bankSupplier?.label,
            value.defaultMethod.bankSupplier?.auxiliaryAccount,
            value.defaultMethod.bankSupplier?.auxiliaryLabel,
            true
          )
        );
      } else {
        this.formArray.controls.forEach((methodConfiguration, index) => {
          if ((value.columns?.name?.canEdit != null && !value.columns?.name?.canEdit) || index === this.formArray.controls.length - 1) {
            methodConfiguration.get('name')?.disable();
          } else {
            methodConfiguration.get('name')?.enable();
          }
          if ((value.columns?.export?.canEdit != null && !value.columns?.export?.canEdit) || index === this.formArray.controls.length - 1) {
            methodConfiguration.get('export')?.disable();
          } else {
            methodConfiguration.get('export')?.enable();
          }
          if ((value.columns?.journalCode?.canEdit != null && !value.columns?.journalCode?.canEdit)) {
            methodConfiguration.get('journalCode')?.disable();
          } else {
            methodConfiguration.get('journalCode')?.enable();
          }
          if ((value.columns?.internalTransfer?.canEdit != null && !value.columns?.internalTransfer?.canEdit)) {
            methodConfiguration.get('internalTransferAccount')?.disable();
            methodConfiguration.get('internalTransferAccountLabel')?.disable();
          } else {
            methodConfiguration.get('internalTransferAccount')?.enable();
            methodConfiguration.get('internalTransferAccountLabel')?.enable();
          }
          if ((value.columns?.provider?.canEdit != null && !value.columns?.provider?.canEdit)) {
            methodConfiguration.get('providerAccount')?.disable();
            methodConfiguration.get('providerAccountLabel')?.disable();
          } else {
            methodConfiguration.get('providerAccount')?.enable();
            methodConfiguration.get('providerAccountLabel')?.enable();
          }
          if ((value.columns?.bankSupplier?.canEdit != null && !value.columns?.bankSupplier?.canEdit)) {
            methodConfiguration.get('bankSupplierAccount')?.disable();
            methodConfiguration.get('bankSupplierAccountLabel')?.disable();
            methodConfiguration.get('bankSupplierAuxiliaryAccount')?.disable();
            methodConfiguration.get('bankSupplierAuxiliaryAccountLabel')?.disable();
          } else {
            methodConfiguration.get('bankSupplierAccount')?.enable();
            methodConfiguration.get('bankSupplierAccountLabel')?.enable();
            methodConfiguration.get('bankSupplierAuxiliaryAccount')?.disable();
            methodConfiguration.get('bankSupplierAuxiliaryAccountLabel')?.disable();
          }
        })
      }

      this.formGroup.markAllAsTouched();
    }
  }

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.formArray = new FormArray<PaymentMethodConfigurationControls>([], [Validators.required]);
    this.formGroup = new FormGroup({
      formArray: this.formArray
    });

  }
  ngOnInit(): void {
    // check for duplicates on init in case user changes nothing in the formArray
    this.checkDuplicatesAndSetLabelsForMethods();
    // subscribe to any formArray changes in case user adds a method
    this.formArraySubscription = this.formArray.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.checkDuplicatesAndSetLabelsForMethods();
    });
  }

  ngOnDestroy(): void {
    this.internalTransferSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formArraySubscription?.unsubscribe();
  }

  addPaymentMethod() {
    const paymentMethodConfigurationGroup: PaymentMethodConfigurationControls = this.createPaymentMethodConfigurationGroup(
      null,
      this.fieldsConfig?.columns?.name?.defaultValue || null,
      this.fieldsConfig?.columns?.export?.defaultValue || null,
      this.fieldsConfig?.columns?.journalCode?.defaultValue || null,
      this.fieldsConfig?.columns?.internalTransfer?.defaultValue?.account || null,
      this.fieldsConfig?.columns?.internalTransfer?.defaultValue?.label || null,
      this.fieldsConfig?.columns?.provider?.defaultValue?.account || null,
      this.fieldsConfig?.columns?.provider?.defaultValue?.label || null,
      this.fieldsConfig?.columns?.bankSupplier?.defaultValue?.account || null,
      this.fieldsConfig?.columns?.bankSupplier?.defaultValue?.label || null,
      this.fieldsConfig?.columns?.bankSupplier?.defaultValue?.auxiliaryAccount || null,
      this.fieldsConfig?.columns?.bankSupplier?.defaultValue?.auxiliaryLabel || null
    );
    this.formArray.insert(this.formArray.controls.length - 1, paymentMethodConfigurationGroup);
  }

  deletePaymentMethod(index: number) {
    this.internalTransferSubscriptions.at(index)?.unsubscribe();
    delete this.internalTransferSubscriptions[index];
    this.formArray.removeAt(index);
  }

  createPaymentMethodConfigurationGroup(
    id: number | null = null,
    name: string | null = null,
    exportValue: boolean | null = null,
    journalCode: string | null = null,
    internalTransferAccount: string | null = null,
    internalTransferAccountLabel: string | null = null,
    providerAccount: string | null = null,
    providerAccountLabel: string | null = null,
    bankSupplierAccount: string | null = null,
    bankSupplierAccountLabel: string | null = null,
    bankSupplierAuxiliaryAccount: string | null = null,
    bankSupplierAuxiliaryAccountLabel: string | null = null,
    isDefault = false
  ): PaymentMethodConfigurationControls {
    let journalCodeValidators: ValidatorFn[] = [];
    if (this.journalOptionValue == undefined || this.journalOptionValue === JournalOptionEnum.BY_PAYMENT_METHOD) {
      journalCodeValidators = [Validators.required];
    }
    const result: PaymentMethodConfigurationControls = new FormGroup({
      id: new FormControl<number | null>(id),
      name: new FormControl<string | null>(name),
      export: new FormControl<boolean | null>(exportValue),
      journalCode: new FormControl<string | null>(journalCode, journalCodeValidators),
      internalTransferAccount: new FormControl<string | null>(internalTransferAccount),
      internalTransferAccountLabel: new FormControl<string | null>(internalTransferAccountLabel),
      providerAccount: new FormControl<string | null>(providerAccount, [Validators.required]),
      providerAccountLabel: new FormControl<string | null>(providerAccountLabel),
      bankSupplierAccount: new FormControl<string | null>(bankSupplierAccount),
      bankSupplierAccountLabel: new FormControl<string | null>(bankSupplierAccountLabel),
      bankSupplierAuxiliaryAccount: new FormControl<string | null>(bankSupplierAuxiliaryAccount),
      bankSupplierAuxiliaryAccountLabel: new FormControl<string | null>(bankSupplierAuxiliaryAccountLabel)
    });
    if ((this.fieldsConfig?.columns?.name?.canEdit != null && !this.fieldsConfig?.columns?.name?.canEdit) || isDefault) {
      result.get('name')?.disable();
    }
    if ((this.fieldsConfig?.columns?.export?.canEdit != null && !this.fieldsConfig?.columns?.export?.canEdit) || isDefault) {
      result.get('export')?.disable();
    }
    if (this.fieldsConfig?.columns?.journalCode?.canEdit != null && !this.fieldsConfig?.columns?.journalCode?.canEdit) {
      result.get('journalCode')?.disable();
    }
    if (this.fieldsConfig?.columns?.internalTransfer?.canEdit != null && !this.fieldsConfig?.columns?.internalTransfer?.canEdit) {
      result.get('internalTransferAccount')?.disable();
      result.get('internalTransferAccountLabel')?.disable();
    }
    if (this.fieldsConfig?.columns?.provider?.canEdit != null && !this.fieldsConfig?.columns?.provider?.canEdit) {
      result.get('providerAccount')?.disable();
      result.get('providerAccountLabel')?.disable();
    }
    if (this.fieldsConfig?.columns?.bankSupplier?.canEdit != null && !this.fieldsConfig?.columns?.bankSupplier?.canEdit) {
      result.get('bankSupplierAccount')?.disable();
      result.get('bankSupplierAccountLabel')?.disable();
      result.get('bankSupplierAuxiliaryAccount')?.disable();
      result.get('bankSupplierAuxiliaryAccountLabel')?.disable();
    }
    this.internalTransferSubscriptions.push(result.controls['name'].valueChanges.pipe(
    ).subscribe((value) => this.filteredOptions$.next(this._filter(value ?? ''))));
    return result;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.paymentOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  checkDuplicatesAndSetLabelsForMethods(): void {
    if (this.fieldsConfig?.unique === false) {
      const providerAccountMap: { [key: string]: string[] } = {};
      const internalTransferAccountMap: { [key: string]: string[] } = {};

      // First loop to add duplicates to the map (account : array of names)
      this.formArray.controls.forEach((control) => {
        const name = control.get('name')?.value;
        const providerAccount = control.get('providerAccount')?.value;
        const internalTransferAccount = control.get('internalTransferAccount')?.value;

        // for the providerAccounts
        if (providerAccount) {
          if (!providerAccountMap[providerAccount]) {
            providerAccountMap[providerAccount] = [];
          }
          if (name && !providerAccountMap[providerAccount].includes(name)) {
            providerAccountMap[providerAccount].push(name);
          }
        }

        // And for the internalTransferAccounts
        if (internalTransferAccount) {
          if (!internalTransferAccountMap[internalTransferAccount]) {
            internalTransferAccountMap[internalTransferAccount] = [];
          }
          if (name && !internalTransferAccountMap[internalTransferAccount].includes(name)) {
            internalTransferAccountMap[internalTransferAccount].push(name);
          }
        }
      });

      // Second loop : for each control we chack if there's more than one name per account and we set the label value
      this.formArray.controls.forEach((control) => {
        const providerAccount = control.get('providerAccount')?.value;
        const internalTransferAccount = control.get('internalTransferAccount')?.value;

        // Provider
        if (providerAccount && providerAccountMap[providerAccount].length > 1) {
          control.get('providerAccountLabel')?.setValue(providerAccountMap[providerAccount].join(' / '));
        } else {
          const name = control.get('name')?.value;
          control.get('providerAccountLabel')?.setValue(name ?? null);
        }

        // InternalTransfer
        if (internalTransferAccount && internalTransferAccountMap[internalTransferAccount].length > 1) {
          control.get('internalTransferAccountLabel')?.setValue(`Virement interne ${internalTransferAccountMap[internalTransferAccount].join(' / ')}`);
        } else {
          const name = control.get('name')?.value;
          control.get('internalTransferAccountLabel')?.setValue(name != null ? `Virement interne ${name}` : null);
        }
      });
    }
  }

}